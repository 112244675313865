import { createAsyncAction } from '@beewise/utils';

export const FETCH_RANCHES_NEW_FLOW = createAsyncAction('FETCH_RANCHES_NEW_FLOW');
export const FETCH_RANCHES_NEW_FLOW_HIVES_AMOUNT = createAsyncAction('FETCH_RANCHES_NEW_FLOW_HIVES_AMOUNT');
export const FETCH_GROWERS_NEW_FLOW = createAsyncAction('FETCH_GROWERS_NEW_FLOW');
export const FETCH_BEEKEEPERS_NEW_FLOW = createAsyncAction('FETCH_BEEKEEPERS_NEW_FLOW');
export const FETCH_INSTALLERS_NEW_FLOW = createAsyncAction('FETCH_INSTALLERS_NEW_FLOW');
export const FETCH_WEATHER_DATA = createAsyncAction('FETCH_WEATHER_DATA');
export const FETCH_GRAPH_DATA = createAsyncAction('FETCH_GRAPH_DATA');
export const FETCH_COMPANY_BY_NAME = createAsyncAction('FETCH_COMPANY_BY_NAME');
export const MODIFY_RANCH_ORDERS = createAsyncAction('MODIFY_RANCH_ORDERS');
export const DELETE_RANCHES_NEW_FLOW = createAsyncAction('DELETE_RANCHES_NEW_FLOW');
export const DELETE_COMPANY_USER_BY_COMPANY_ID = createAsyncAction('DELETE_COMPANY_USER_BY_COMPANY_ID');
export const SEND_SMS_WITH_COORDINATES = createAsyncAction('SEND_SMS_WITH_COORDINATES');
export const SEND_RANCH_REPORT = createAsyncAction('SEND_RANCH_REPORT');
export const CREATE_COMPANY_USER = createAsyncAction('CREATE_COMPANY_USER');
export const UPDATE_COMPANY_USER = createAsyncAction('UPDATE_COMPANY_USER');
export const REQUEST_RANCH_APPROVAL = createAsyncAction('REQUEST_RANCH_APPROVAL');
export const SELECT_CURRENT_ITEM = 'SELECT_CURRENT_ITEM';
export const DESELECT_CURRENT_ITEM = 'DESELECT_CURRENT_ITEM';
export const TOGGLE_COUNTRIES = 'TOGGLE_COUNTRIES';
export const FETCH_COMPANY_USERS = createAsyncAction('FETCH_COMPANY_USERS');
