import React from 'react';

const SvgBackground = () => (
    <div className="background">
        <svg width="480" height="421" viewBox="0 0 480 421" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M105.765 248.19H150.337L172.627 210.871L150.337 173.552H105.765L83.4835 210.871L105.765 248.19ZM155.03 256.043H101.08L74.1055 210.871L101.08 165.698H155.03L182.005 210.871L155.03 256.043Z"
                fill="#F7B51E"
                fillOpacity="0.05"
            />
            <path
                d="M106.876 165.178H151.448L173.738 127.859L151.448 90.5401H106.876L84.5938 127.859L106.876 165.178ZM156.141 173.031H102.191L75.2158 127.859L102.191 82.6868H156.141L183.116 127.859L156.141 173.031Z"
                fill="#F7B51E"
                fillOpacity="0.05"
            />
            <path
                d="M105.968 82.5606H150.54L172.83 45.2417L150.54 7.92276H105.968L83.6861 45.2417L105.968 82.5606ZM155.233 90.4139H101.283L74.3081 45.2417L101.283 0.069458H155.233L182.208 45.2417L155.233 90.4139Z"
                fill="#F7B51E"
                fillOpacity="0.05"
            />
            <path
                d="M106.373 330.31H150.945L173.235 292.991L150.945 255.672H106.373L84.0914 292.991L106.373 330.31ZM155.638 338.163H101.688L74.7134 292.991L101.688 247.819H155.638L182.613 292.991L155.638 338.163Z"
                fill="#F7B51E"
                fillOpacity="0.05"
            />
            <path
                d="M32.1192 205.769H76.6912L98.9731 168.45L76.6912 131.131H32.1192L9.8292 168.45L32.1192 205.769ZM81.3762 213.622H27.4262L0.451172 168.45L27.4262 123.278H81.3762L108.351 168.45L81.3762 213.622Z"
                fill="#F7B51E"
                fillOpacity="0.05"
            />
            <path
                d="M180.06 289.065H224.624L246.914 251.746L224.624 214.419H180.06L157.77 251.746L180.06 289.065ZM229.317 296.918H175.367L148.392 251.746L175.367 206.573H229.317L256.292 251.746L229.317 296.918Z"
                fill="#F7B51E"
                fillOpacity="0.05"
            />
            <path
                d="M179.939 206.053H224.511L246.801 168.734L224.511 131.407H179.939L157.657 168.734L179.939 206.053ZM229.204 213.906H175.254L148.279 168.734L175.254 123.562H229.204L256.179 168.734L229.204 213.906Z"
                fill="#F7B51E"
                fillOpacity="0.05"
            />
            <path
                d="M254.298 247.535H298.87L321.16 210.216L298.87 172.897H254.298L232.016 210.216L254.298 247.535ZM303.563 255.388H249.613L222.638 210.216L249.613 165.044H303.563L330.538 210.216L303.563 255.388Z"
                fill="#F7B51E"
                fillOpacity="0.05"
            />
            <path
                d="M180.846 371.832H225.418L247.7 334.513L225.418 297.194H180.846L158.564 334.513L180.846 371.832ZM230.111 379.685H176.153L149.178 334.513L176.153 289.341H230.111L257.078 334.513L230.111 379.685Z"
                fill="#F7B51E"
                fillOpacity="0.05"
            />
            <path
                d="M106.876 413.085H151.448L173.738 375.758L151.448 338.439H106.876L84.5938 375.758L106.876 413.085ZM156.141 420.938H102.191L75.2158 375.758L102.191 330.586H156.141L183.116 375.758L156.141 420.938Z"
                fill="#F7B51E"
                fillOpacity="0.05"
            />
            <path
                d="M255.092 330.31H299.664L321.946 292.991L299.664 255.672H255.092L232.802 292.991L255.092 330.31ZM304.349 338.163H250.399L223.424 292.991L250.399 247.819H304.349L331.324 292.991L304.349 338.163Z"
                fill="#F7B51E"
                fillOpacity="0.05"
            />
            <path
                d="M328.48 205.399H373.043L395.333 168.08L373.043 130.761H328.48L306.19 168.08L328.48 205.399ZM377.737 213.252H323.787L296.812 168.08L323.787 122.907H377.737L404.712 168.08L377.737 213.252Z"
                fill="#F7B51E"
                fillOpacity="0.05"
            />
            <path
                d="M180.441 122.884H225.013L247.295 85.5648L225.013 48.2458H180.441L158.151 85.5648L180.441 122.884ZM229.698 130.745H175.748L148.773 85.5648L175.748 40.3925H229.698L256.673 85.5648L229.698 130.745Z"
                fill="#F7B51E"
                fillOpacity="0.05"
            />
            <path
                d="M254.801 164.374H299.373L321.655 127.055L299.373 89.7358H254.801L232.511 127.055L254.801 164.374ZM304.058 172.227H250.108L223.133 127.055L250.108 81.8825H304.058L331.033 127.055L304.058 172.227Z"
                fill="#F7B51E"
                fillOpacity="0.05"
            />
            <path
                d="M403.309 163.609H447.881L470.171 126.282L447.881 88.9631H403.309L381.019 126.282L403.309 163.609ZM452.566 171.462H398.616L371.641 126.282L398.616 81.1098H452.566L479.541 126.282L452.566 171.462Z"
                fill="#F7B51E"
                fillOpacity="0.05"
            />
            <path
                d="M328.974 122.237H373.546L395.828 84.9182L373.546 47.5993H328.974L306.684 84.9182L328.974 122.237ZM378.231 130.09H324.281L297.306 84.9182L324.281 39.746H378.231L405.206 84.9182L378.231 130.09Z"
                fill="#F7B51E"
                fillOpacity="0.05"
            />
        </svg>
    </div>
);

export default SvgBackground;
