import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { noop } from 'lodash-es';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import { shallowEqual } from '@beewise/react-utils';
import cn from 'classnames';
import Icon from '@beewise/icon';
import PropTypes from 'prop-types';
import constants from 'appConstants';
import { getLocationType, getCoefficient, getDistanceRadius, calculateMarkerStyle } from 'utils';
import './LocationMarker.scss';
import { getBeekeepersColorsById } from 'components/views/AdminView/selectors';
import MapMarkerWrapper from '../MapMarkerWrapper';

// ensure halo is ~316m (default is ~400m)
const distanceCoefficient = coef => ({ '--data-coefficient': coef });

const { MARKER_TYPE } = constants;

const getBhomeText = bhomeIds => {
    if (!bhomeIds?.length) {
        return null;
    }
    if (bhomeIds.length === 1) {
        return `BH ID: ${bhomeIds[0]}`;
    }
    return (
        <>
            BH IDs: <br />{' '}
            {bhomeIds?.map(id => (
                <>
                    {id}
                    <br />
                </>
            ))}
        </>
    );
};

const getTooltipData = ({ marker, isGrower }) => {
    const name = `Drop Name: ${marker?.name}`;
    const header = `Hives: ${marker.hivesAmount || constants.DEFAULT_HIVES_AMOUNT}`;
    const bhomeIds = getBhomeText(marker.bhomeIds);
    // comment out for now as not all bhomes are making scans
    // const { frameTitle, standardFrames, actualFrames } = marker.bhomeId
    //     ? getAverageFramesData(framesPerHiveByBhomeId, marker, selectedRanchQuality)
    //     : {};
    return (
        <div className="bhome-marker-tooltip">
            {name && <p>{name}</p>}
            {!isGrower && header && <p>{header}</p>}
            {!isGrower && bhomeIds && <p>{bhomeIds}</p>}
            {/* {frameTitle && <span className="tooltip-title">{frameTitle}</span>} */}
            {/* {standardFrames && <p>{standardFrames}</p>} */}
            {/* {actualFrames && <p>{actualFrames}</p>} */}
            {marker?.note && <p>{marker.note}</p>}
        </div>
    );
};

const LocationMarker = ({
    country = 'us',
    marker,
    ratio,
    contentData,
    onMouseLeave,
    onMouseEnter,
    onDragStart,
    draggable,
    selectedRanchQuality,
    isGrower,
    ...props
}) => {
    const beekeepersColorsById = useSelector(getBeekeepersColorsById, shallowEqual);
    const type = getLocationType(marker);
    const style = calculateMarkerStyle({ marker });
    const distance =
        country && getDistanceRadius(marker.hivesAmount || constants.DEFAULT_HIVES_AMOUNT, ratio || 2, country);
    const coefficient = getCoefficient(distance);
    const haloStyle = distanceCoefficient(coefficient);

    const halo = <div className="halo" style={haloStyle} />;

    const isEmptyLocation = type === MARKER_TYPE.EMPTY_LOCATION;

    const icon = useMemo(
        () =>
            [MARKER_TYPE.EMPTY_LOCATION, MARKER_TYPE.STANDARD_HIVE].includes(type) ? (
                <FontAwesomeIcon icon={faLocationDot} className={cn('marker-location', style)} />
            ) : (
                <Icon
                    className={cn(style, { 'marker-large': marker?.bhomeIds.length > 1 })}
                    type="bhome-marker"
                    pointer="true"
                />
            ),
        [type, style, marker.bhomeIds?.length]
    );

    const draggableProps = {
        onMouseEnter: isEmptyLocation ? onMouseEnter : noop,
        onMouseLeave: isEmptyLocation ? onMouseLeave : noop,
        draggable: isEmptyLocation ? draggable : false,
        onDragStart: isEmptyLocation ? onDragStart : noop,
    };

    const tooltipData = getTooltipData({ marker, isGrower, selectedRanchQuality });

    return (
        <MapMarkerWrapper
            marker={marker}
            country={country}
            halo={halo}
            tooltipData={tooltipData}
            icon={icon}
            type={type}
            isBhomeMarker={!!marker?.bhomeIds?.length}
            color={
                marker.beekeeperId && beekeepersColorsById[marker.beekeeperId]
                    ? beekeepersColorsById[marker.beekeeperId]
                    : null
            }
            {...props}
            {...draggableProps}
        />
    );
};

LocationMarker.propTypes = {
    cursorStyle: PropTypes.shape(),
    isDisabled: PropTypes.bool,
    marker: PropTypes.shape().isRequired,
    onClick: PropTypes.func.isRequired,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    selectedItem: PropTypes.shape(),
    contentData: PropTypes.shape(),
    appSettings: PropTypes.shape(),
    ratio: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    country: PropTypes.string,
    draggable: PropTypes.bool,
    onDragStart: PropTypes.func,
    selectedRanchQuality: PropTypes.number,
    isGrower: PropTypes.bool,
};

export default LocationMarker;
