import { createSelector } from 'reselect';
import { prepareRanchContentData } from 'utils/prepareContentData';

export const getRanches = state => state.growerView.ranches;
export const getRanchContentData = state => state.growerView.content;
export const getRanchBroker = state => state.growerView.ranchBroker;
export const getFramesPerHiveByBhomeId = state => state.growerView.framesPerHiveByBhomeId;
export const getSelectedRanch = state => state.growerView.selectedRanch;
export const getSelectedRanchApproval = state => state.growerView.selectedRanchApproval;
export const getBeeActivitiesOverTime = state => state.growerView.beeActivitiesOverTime;

export const getFilteredContentData = createSelector(getRanchContentData, prepareRanchContentData);
