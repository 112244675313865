/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import constants from 'appConstants';
import { convertArea, getUnits, getRatioHeader } from 'utils/isIsraeliRanch';
import Card from 'components/reusables/Card';
import DeliveryStatus from './DeliveryStatus';

const RanchDetails = ({ ranch }) => {
    const totalArea = convertArea(ranch.totalArea, ranch?.country).toFixed();
    const units = getUnits(ranch.country).area;
    const ratioHeader = useMemo(() => getRatioHeader(ranch?.country), [ranch?.country]);

    const orderedHives = ranch.locations.reduce((acc, location) => {
        acc += location.hivesAmount || constants.DEFAULT_HIVES_AMOUNT;
        return acc;
    }, 0);

    const placedHives = ranch.locations.reduce((acc, location) => {
        acc += location.placedHivesAmount || 0;
        return acc;
    }, 0);
    const generalSectionFields = [
        { name: 'Total area', value: `${totalArea} ${units}` },
        { name: 'Crop', value: ranch.crop?.split('_')?.join(' ') },
        ...(ranch.note ? [{ name: 'Notes', value: ranch.note }] : []),
        ...(ranch?.trees?.length
            ? [
                  {
                      name: 'Tree Varieties',
                      value: ranch?.trees.map(item => `${item.name} ${item.percent}%`).join(', '),
                  },
              ]
            : []),
    ];

    const pollinationDetailFields = [
        { name: ratioHeader, value: ranch.ratio },
        { name: 'Required number of hives', value: ranch.requiredHivesAmount },
        { name: 'Ordered hives', value: orderedHives },
        { name: 'Placed hives', value: placedHives },
        { name: 'Total hive drops', value: ranch.locations.length },
    ];

    const lastOrder = ranch?.orders?.length ? ranch?.orders?.[ranch.orders.length - 1] : null;

    return (
        <div className="right-panel-ranch">
            {lastOrder?.status && lastOrder.status !== constants.ORDER_STATUSES.ORDERED && (
                <DeliveryStatus order={lastOrder} />
            )}
            <Card title="General" items={generalSectionFields} />
            <Card title="Pollination Details" items={pollinationDetailFields} />
            {/* // DEMO comment<div className="divider"/><PollinationSavings ranch={ranch}/> */}
        </div>
    );
};

export default RanchDetails;

RanchDetails.propTypes = {
    ranch: PropTypes.shape(),
};
