import React from 'react';
import PropTypes from 'prop-types';

import './ForgotPasswordSentMessage.scss';

const ForgotPasswordSentMessage = ({ email, handleForgotPassword }) => (
  <div className="forgot-password">
    <div className="forgot-password-margin">We have sent a Reset Password email to</div>
    <div className="forgot-password">{email}</div>
    <div className="forgot-password-margin">
      Follow the directions in the email to reset your password.
    </div>
    <div className="forgot-password-tip forgot-password-margin">
      <div>Haven&#39;t received the email yet?</div>
      <div>
        Please check your spam and promotions folders, or{' '}
        <button className="forgot-password-resend" onClick={handleForgotPassword}>
          resend
        </button>{' '}
        the email.
      </div>
    </div>
  </div>
);

ForgotPasswordSentMessage.propTypes = {
  email: PropTypes.string.isRequired,
  handleForgotPassword: PropTypes.func.isRequired
};

export default ForgotPasswordSentMessage;
