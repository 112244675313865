import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import cx from 'classnames';
import storage from 'local-storage-fallback';
import constants from 'appConstants';
import './Cursor.scss';
import { getUnits, convertDistance } from 'utils/isIsraeliRanch';
import { getCoefficient, getDistanceRadius, getShowDistanceOnHalo, hivesAmountKey } from 'utils';
import { useEventListener } from './useEventListener';

const Line = ({ distance, position }) => (
    <>
        <span className="cursor-halo-distance-line" />
        <span className={`cursor-halo-distance-${position}`}>{distance}</span>
        <span className="cursor-halo-distance-line" />
    </>
);

const Cursor = ({ map, zoom, country = 'us', ratio }) => {
    const cursorInnerRef = useRef();
    const [isPositionCalculated, setIsPositionCalculated] = useState(false);

    const onMouseMove = ({ clientX, clientY }) => {
        if (cursorInnerRef.current?.style) {
            cursorInnerRef.current.style.top = `${clientY}px`;
            cursorInnerRef.current.style.left = `${clientX}px`;
            if (!isPositionCalculated) {
                setIsPositionCalculated(true);
            }
        }
    };

    useEventListener('mousemove', onMouseMove, map);

    const { distance: distanceUnits } = getUnits(country);
    const hivesAmount = storage.getItem(hivesAmountKey) || constants.DEFAULT_HIVES_AMOUNT;
    const distance = getDistanceRadius(Number(hivesAmount), ratio, country);
    const coefficient = getCoefficient(distance);
    const haloStyle = { '--data-coefficient': coefficient };
    const showDistanceOnHalo = getShowDistanceOnHalo(zoom, coefficient);
    const distanceText = `${convertDistance(distance, country)} ${distanceUnits}`;

    return (
        <div ref={cursorInnerRef} className="cursor" style={{ opacity: isPositionCalculated ? 1 : 0 }}>
            <div className={cx('halo', `map-zoom-${zoom}`, { 'cursor-halo': showDistanceOnHalo })} style={haloStyle}>
                {showDistanceOnHalo && (
                    <div className="cursor-halo-distance">
                        <Line distance={distanceText} position="right" />
                        <Line distance={distanceText} position="left" />
                    </div>
                )}
            </div>
            <FontAwesomeIcon icon={faLocationDot} />
        </div>
    );
};

Cursor.propTypes = {
    map: PropTypes.shape(),
    zoom: PropTypes.number,
    country: PropTypes.string,
    ratio: PropTypes.number,
};

Line.propTypes = {
    distance: PropTypes.string,
    position: PropTypes.oneOf(['left', 'right']),
};

export default Cursor;
