import React from 'react';
import PropTypes from 'prop-types';
import HivesInfoContainer from './HivesInfoContainer';
import AddRanch from './AddRanch';
import SearchRanches from './SearchRanches';
import RanchesList from './RanchesList';
import './ControlsView.scss';

const ControlsView = ({ onSearch, map }) => (
    <div className="controls-container">
        <HivesInfoContainer />
        <AddRanch map={map} />
        <SearchRanches onSearch={onSearch} />
        <RanchesList />
    </div>
);

export default ControlsView;

ControlsView.propTypes = {
    onSearch: PropTypes.func,
    map: PropTypes.shape({}),
};
