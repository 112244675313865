import React from 'react';
import Menu from '@beewise/menu';
import { useDispatch, useSelector } from 'react-redux';
import Toggle from '@beewise/toggle';
import IconButton from '@beewise/icon-button';
import { faUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket } from '@fortawesome/pro-solid-svg-icons';
import { toggleCountries } from 'components/views/AdminView/actions';
import { signOut } from 'components/views/SignIn/actions/sign.in.actions';

const UserMenu = () => {
    const selectedCountries = useSelector(state => state.adminDashboard.countriesToShow);

    const dispatch = useDispatch();
    const isCountryShown = country => selectedCountries.includes(country);

    const handleSignOut = () => dispatch(signOut());

    const handleToggleContry = country => () => dispatch(toggleCountries(country));

    return (
        <div className="main-menu">
            <Menu
                customComponent={<IconButton icon={faUser} className="user-icon" />}
                position="left top"
                options={[
                    {
                        label: (
                            <div className="main-menu-item">
                                <Toggle
                                    name="showUS"
                                    id="showUS"
                                    value={isCountryShown('us')}
                                    onChange={handleToggleContry('us')}
                                />
                                Show US
                            </div>
                        ),
                    },
                    {
                        label: (
                            <div className="main-menu-item">
                                <Toggle
                                    name="showIL"
                                    id="showIL"
                                    value={isCountryShown('il')}
                                    onChange={handleToggleContry('il')}
                                />
                                Show IL
                            </div>
                        ),
                    },
                    {
                        label: (
                            <div className="main-menu-item">
                                <FontAwesomeIcon icon={faArrowRightFromBracket} className="logout-icon" />
                                Logout
                            </div>
                        ),
                        onClick: handleSignOut,
                    },
                ]}
            />
        </div>
    );
};

export default UserMenu;
