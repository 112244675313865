import {
    FETCH_USER_SETTINGS,
    FETCH_UPDATE_USER_SETTINGS,
    FETCH_UPDATE_MILESTONES,
    UPDATE_COMPANY_LAST_VISITED,
    FETCH_APP_SETTINGS,
} from '../actionTypes';

export const fetchAppSettings = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_APP_SETTINGS.default,
        url: '/settings/app/grower',
    },
});

export const fetchUserSettings = () => ({
    type: 'FETCH',
    params: {
        type: FETCH_USER_SETTINGS.default,
        url: '/users/get-settings',
    },
});

export const fetchUpdateUserSettings = bhomesDelivered => ({
    type: 'FETCH',
    params: {
        method: 'PUT',
        type: FETCH_UPDATE_USER_SETTINGS.default,
        url: '/users/update-settings',
        body: {
            settings: {
                growerNotifications: {
                    bhomesDelivered,
                },
            },
        },
    },
});

export const fetchUpdateMilestones = (body, resolver) => ({
    type: 'FETCH',
    params: {
        type: FETCH_UPDATE_MILESTONES.default,
        method: 'PATCH',
        url: '/ranch/orders/batch',
        body,
        resolver,
    },
});

export const fetchUpdateLastVisited = id => ({
    type: 'FETCH',
    params: {
        type: UPDATE_COMPANY_LAST_VISITED.default,
        url: `/companies/${id}/last-visited`,
        method: 'PUT',
    },
});
