import React, { useCallback } from 'react';
import { useToggle } from '@beewise/react-utils';
import PropTypes from 'prop-types';
import Accordion from '@beewise/accordion-custom';
import Button from '@beewise/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencil } from '@fortawesome/pro-regular-svg-icons';
import { MODALS } from 'components/reusables/ModalManager/config';
import { modalController } from 'components/reusables/ModalManager/modalController';
import { getUnitsDefinitionFromCountry } from 'utils/isIsraeliRanch';

const BlockItem = ({
    name,
    description,
    id,
    handleRemoveBlock,
    setBlockEdited,
    polygon,
    totalArea,
    ratio,
    trees,
    year,
    country,
}) => {
    const handleSetBlockEdited = useCallback(() => {
        setBlockEdited({ id, polygon });
    }, [id, polygon, setBlockEdited]);

    const [isOpen, toggleIsOpen] = useToggle(false);

    const handleOpenRemoveBlockModal = () =>
        modalController.set({
            name: MODALS.REMOVE_CONFIRMATION,
            props: {
                onConfirm: () => handleRemoveBlock(id),
                header: 'Delete block',
                info: 'Are you sure you want to delete this block?',
            },
        });

    const treesNames = trees?.map(({ tree }) => tree).join(', ');

    return (
        <Accordion title={name} description={description} isOpen={isOpen} onToggle={toggleIsOpen} color="purple">
            <div className="accordion-container">
                <div className="accordion-content">
                    <div className="accordion-content__item-title">{getUnitsDefinitionFromCountry(country)}</div>
                    <div className="accordion-content__item-value">{totalArea}</div>

                    <div className="accordion-content__item-title">Ratio</div>
                    <div className="accordion-content__item-value">{ratio}</div>

                    <div className="accordion-content__item-title">Tree variety</div>
                    <div className="accordion-content__item-value">{treesNames}</div>

                    <div className="accordion-content__item-title">Year planted</div>
                    <div className="accordion-content__item-value">{year}</div>
                </div>
                <div className="accordion-buttons">
                    <Button className="text-black" onClick={handleSetBlockEdited}>
                        <FontAwesomeIcon icon={faPencil} />
                        Edit
                    </Button>
                    <Button className="text-black" onClick={handleOpenRemoveBlockModal}>
                        <FontAwesomeIcon icon={faTrash} />
                        Delete
                    </Button>
                </div>
            </div>
        </Accordion>
    );
};

BlockItem.propTypes = {
    name: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.string,
    handleRemoveBlock: PropTypes.func,
    setBlockEdited: PropTypes.func,
    polygon: PropTypes.arrayOf(PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number })),
    year: PropTypes.number,
    trees: PropTypes.arrayOf(PropTypes.shape({ tree: PropTypes.string, percent: PropTypes.number })),
    ratio: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    totalArea: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    country: PropTypes.string.isRequired,
};

const BlockList = ({ blocks, blockSums = {}, handleRemoveBlock, setBlockEdited, country }) => (
    <>
        <div className="left-panel-small-header">Ranch blocks</div>
        {blocks.map((blockProps, index) => (
            <BlockItem
                {...blockProps}
                index={index}
                description={`Planned: ${blockProps.hivesAmount}, Actual: ${blockSums[blockProps.id] ?? 0} hives`}
                key={blockProps.id}
                handleRemoveBlock={handleRemoveBlock}
                setBlockEdited={setBlockEdited}
                country={country}
            />
        ))}
    </>
);

BlockList.propTypes = {
    blocks: PropTypes.arrayOf(PropTypes.shape()),
    blockSums: PropTypes.shape({}),
    handleRemoveBlock: PropTypes.func,
    setBlockEdited: PropTypes.func,
    id: PropTypes.string,
    country: PropTypes.string.isRequired,
};

export default BlockList;
