import { useEffect } from 'react';

const useDebounceEffect = (effect, deps = [], delay) =>
    useEffect(() => {
        const timeoutId = setTimeout(effect, delay);

        return () => clearTimeout(timeoutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [delay, ...deps]);

export default useDebounceEffect;
