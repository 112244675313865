import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import constants from 'appConstants';
import { COUNTRY_IL, COUNTRY_US } from 'utils/isIsraeliRanch';
import { fetchCompaniesByType, fetchCompanyUsers } from 'components/views/AdminView/actions';
import { loading } from '@beewise/react-utils';
import LeftPanel from './components/LeftPanel';
import './BrokerRanch.scss';
import Map from './components/Map';
import useDrawingMode from './hooks/useDrawingMode';
import useInitFormData from './hooks/useInitFormData';
import { CREATE_RANCH, UPDATE_RANCH } from './actionTypes';

const BrokerRanch = () => {
    const dispatch = useDispatch();
    const [blockEdited, setBlockEdited] = useState({});
    const [toolboxOption, setToolboxOption] = useState(null);
    const form = useInitFormData();
    const [companyId, accessRoadCoordinates, country] = form.watch(['companyId', 'accessRoadCoordinates', 'country']);

    const {
        polygons,
        isBlockWithoutPolygon,
        removePolygonFromView,
        setMap,
        map,
        handleCancelPolygonSave,
        handleSavePolygon,
        removePolygon,
        newPolygon,
        drawingManager,
    } = useDrawingMode({ blockEdited, toolboxOption, setValue: form.setValue, accessRoadCoordinates });

    useEffect(() => {
        dispatch(fetchCompaniesByType(constants.COMPANY_TYPES.GROWER));
    }, [dispatch]);

    useEffect(() => {
        form.resetField('contact');
        form.resetField('ranchManager');
        if (companyId && companyId !== constants.INVITED_ID) {
            dispatch(fetchCompanyUsers(companyId));
        }
    }, [companyId, dispatch, form]);

    useEffect(() => {
        if (!map) {
            return;
        }

        const handleCenterChanged = () => {
            const { lng } = map.getCenter();
            const currentLng = lng();
            const currentDefaultCountry = currentLng > 0 ? COUNTRY_IL : COUNTRY_US;

            if (currentDefaultCountry !== country) {
                form.setValue('country', currentDefaultCountry, { shouldDirty: true });
            }
        };

        handleCenterChanged();

        window?.google?.maps?.event?.addListener(map, 'center_changed', handleCenterChanged);

        return () => window?.google?.maps?.event?.clearListeners(map, 'center_changed');
    }, [companyId, country, form, map]);

    return (
        form && (
            <div className="broker-ranch">
                <LeftPanel
                    form={form}
                    removePolygon={removePolygon}
                    blockEdited={blockEdited}
                    setBlockEdited={setBlockEdited}
                    handleCancelPolygonSave={handleCancelPolygonSave}
                    handleSavePolygon={handleSavePolygon}
                    isBlockWithoutPolygon={isBlockWithoutPolygon}
                    newPolygon={newPolygon}
                    polygons={polygons}
                    drawingManager={drawingManager}
                />
                <Map
                    blockEdited={blockEdited}
                    polygons={polygons}
                    removePolygonFromView={removePolygonFromView}
                    setMap={setMap}
                    map={map}
                    drawingManager={drawingManager}
                    toolboxOption={toolboxOption}
                    setToolboxOption={setToolboxOption}
                    form={form}
                />
            </div>
        )
    );
};

export default loading([UPDATE_RANCH.default, CREATE_RANCH.default])(BrokerRanch);
