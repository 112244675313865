import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useState, useLayoutEffect, useRef } from 'react';
import { faPencil } from '@fortawesome/pro-light-svg-icons';
import IconButtonV2 from 'components/reusables/IconButtonV2';
import { InputFormField } from '@beewise/hook-form';

const Header = ({ name, control }) => {
    const { id: isEditMode } = useParams();
    const [isNameEdited, setIsNameEdited] = useState(false);
    const handleEnableSetIsNameEdited = () => setIsNameEdited(true);

    const shouldDisplayInput = !isEditMode || isNameEdited;

    const inputRef = useRef(null);

    useLayoutEffect(() => {
        const handleOutsideClick = event => {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                setIsNameEdited(prevVal => !prevVal);
            }
        };

        if (isNameEdited) {
            setTimeout(() => {
                document.addEventListener('click', handleOutsideClick);
            }, 1000);
        }

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isNameEdited]);

    return (
        <>
            {!isEditMode && (
                <div>
                    <div className="left-panel-header">Create Ranch</div>
                    <div className="left-panel-subheader">Fill in the information required about this ranch.</div>
                </div>
            )}

            {isEditMode && !isNameEdited && (
                <div className="left-panel-header">
                    <span>{name}</span> <IconButtonV2 icon={faPencil} onClick={handleEnableSetIsNameEdited} />
                </div>
            )}

            {shouldDisplayInput && (
                <InputFormField
                    ref={inputRef}
                    name="name"
                    control={control}
                    label="Ranch name"
                    required
                    className="text-field--first"
                />
            )}
        </>
    );
};

Header.propTypes = {
    name: PropTypes.string,
    control: PropTypes.shape().isRequired,
    errors: PropTypes.shape({}),
};

export default Header;
