import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import TextField from '@beewise/text-field';
import { SelectField } from '@beewise/select-field';
import { debounce } from 'lodash-es';
import './SearchRanches.scss';
import { useSelector } from 'react-redux';

const FILTERS = {
    ALL: 'all',
    ASSIGNED: 'assigned',
    UNASSIGNED: 'unassigned',
};

const getFiltersOptions = countriesToShow => [
    { label: countriesToShow.length ? 'All ranches' : 'All my ranches', value: FILTERS.ALL },
    { label: 'Assigned', value: FILTERS.ASSIGNED },
    { label: 'Unassigned', value: FILTERS.UNASSIGNED },
];

const SearchRanches = ({ onSearch }) => {
    const [query, setQuery] = useState('');
    const [filter, setFilter] = useState(FILTERS.ALL);

    const countriesToShow = useSelector(state => state.adminDashboard.countriesToShow);

    useEffect(() => {
        setQuery('');
        setFilter(FILTERS.ALL);
    }, [countriesToShow]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedOnSearch = useCallback(debounce(onSearch, 500), []);

    const handleChangeQuery = useCallback(
        value => {
            setQuery(value);
            debouncedOnSearch({ search: value, country: countriesToShow, filter });
        },
        [countriesToShow, debouncedOnSearch, filter]
    );

    const handleSelectChange = useCallback(
        value => {
            const valueToPass = value === FILTERS.ALL ? '' : value;
            setFilter(value);
            debouncedOnSearch({ search: query, country: countriesToShow, filter: valueToPass });
        },
        [countriesToShow, debouncedOnSearch, query]
    );

    useEffect(() => () => debouncedOnSearch.cancel(), [debouncedOnSearch]);

    return (
        <div className="search-ranches-container">
            <SelectField
                className="search-select-field"
                name="filter"
                label="Filter by"
                options={getFiltersOptions(countriesToShow)}
                value={filter}
                onChange={handleSelectChange}
            />
            <TextField
                className="search-text-field"
                name="Search"
                label="Search for ranch/beekeeper/installer"
                value={query}
                onChange={handleChangeQuery}
            />
        </div>
    );
};

SearchRanches.propTypes = {
    onSearch: PropTypes.func.isRequired,
};

export default SearchRanches;
