import React from 'react';
import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';
import { loading } from '@beewise/react-utils';
import { FETCH_RANCHES_NEW_FLOW_HIVES_AMOUNT } from '../../../../actionTypes';
import './HivesInfoBlock.scss';

const HivesInfoBlockContentLoader = () => (
    <ContentLoader width={124} height={68} speed={1} backgroundColor="#EEEEEE" foregroundColor="#BDBDBD">
        <rect x="0" y="0" rx="3" ry="3" width="124" height="40" />
        <rect x="0" y="48" rx="3" ry="3" width="124" height="20" />
    </ContentLoader>
);

const HivesInfoBlock = ({ title, description }) => (
    <div className="hive-info-block">
        <h3 className="info-block-title">{title}</h3>
        <p className="info-block-description">{description}</p>
    </div>
);

HivesInfoBlock.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    description: PropTypes.string,
};

export default loading(FETCH_RANCHES_NEW_FLOW_HIVES_AMOUNT.default, HivesInfoBlockContentLoader)(HivesInfoBlock);
