import { useCallback } from 'react';
import { getDirtyFormFields } from '@beewise/react-utils';
import { useDispatch } from 'react-redux';
import { ROOT_ERRORS } from '../../components/reusables/Form/Errors';

const removeEmptyFields = data => {
    Object.keys(data).forEach(key => {
        if (data[key] === '' || data[key] === null) {
            delete data[key];
        }
    });
    return data;
};

const useSubmitForm = ({
    form,
    onSubmit,
    prepareData,
    isEditMode,
    fieldsToStringify,
    validateDirtyFields,
    preSubmit,
    resolver,
}) => {
    const dispatch = useDispatch();
    const {
        formState: { dirtyFields, defaultValues: initialFields },
        setError,
    } = form;

    const handleSetError = useCallback(
        error => setError(ROOT_ERRORS.SERVER_ERROR, { message: error, type: 500 }),
        [setError]
    );

    const handleSubmitForm = useCallback(
        state => {
            if (!onSubmit) {
                return;
            }

            let enhancedData = state;

            if (prepareData) {
                enhancedData = prepareData(state);
            }

            if (isEditMode) {
                const dirtyFieldsToSubmit = getDirtyFormFields({
                    dirtyFields,
                    initialFields,
                    newFields: enhancedData,
                    fieldsToStringify,
                });

                if (validateDirtyFields) {
                    const isFormValid = validateDirtyFields({
                        dirtyFieldsToSubmit,
                        handleSetError,
                    });

                    if (!isFormValid) {
                        return;
                    }
                }

                const cleanedData = preSubmit(dirtyFieldsToSubmit);

                return dispatch(onSubmit(cleanedData, resolver, handleSetError));
            }

            const formWithoutEmptyField = removeEmptyFields(enhancedData);
            return dispatch(onSubmit(formWithoutEmptyField, resolver, handleSetError));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isEditMode, initialFields, prepareData, dirtyFields, fieldsToStringify, handleSetError, validateDirtyFields]
    );

    return handleSubmitForm;
};

export default useSubmitForm;
