import { createSelector } from 'reselect';
import { prepareBeekeepersColorsById } from 'utils/prepareContentData';

export const getRanches = state => state.adminDashboard.ranches;
export const getHivesAmount = state => state.adminDashboard.hivesAmount;
export const getSelectedItem = state => state.adminDashboard.selectedItem;
export const getGrowers = state => state.adminDashboard.growers;
export const getBeekeepers = state => state.adminDashboard.beekeepers;
export const getInstallers = state => state.adminDashboard.installers;

export const getBeekeepersColorsById = createSelector(getBeekeepers, prepareBeekeepersColorsById);
