import { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { getPolygonArea, getPolygonCountry, getCursor } from 'utils';
import constants from 'appConstants';
import { useLocation } from 'react-router-dom';
import { createDrawingManager, COMPLETED_POLYGON_OPTIONS } from '../utils';
import useCreateInitialFigures from './useCreateInitialFigures';

export const getPolygonCoords = polygon =>
    polygon
        .getPath()
        .getArray()
        .map(item => ({
            lat: item.lat(),
            lng: item.lng(),
        }));

const makePolygonEditable = polygon => {
    polygon.setDraggable(true);
    polygon.setEditable(true);
    window.google.maps.event.clearListeners(polygon, 'click');
    polygon.setOptions({
        draggableCursor: 'default',
        cursor: 'default',
    });
};

const getDrawingMode = ({ blockEditedId, toolboxOption }) => {
    if (blockEditedId) {
        return 'polygon';
    }
    if (toolboxOption === constants.TOOLBOX_MODE.MARK_ACCESS_ROAD) {
        return 'polyline';
    }
    return null;
};

const useDrawingMode = ({ blockEdited, toolboxOption, setValue, accessRoadCoordinates }) => {
    const [map, setMap] = useState(null);
    const [drawingManager, setDrawingManager] = useState(null);
    const [polygons, setPolygons] = useState([]);
    const [newPolygon, setNewPolygon] = useState(null);
    const [isCurrentPolygonDeleted, setIsCurrentPolygonDeleted] = useState(null);

    const accessRoadRef = useRef(null);

    // open the same location from search
    const location = useLocation();

    const drawingMode = getDrawingMode({ blockEditedId: blockEdited.id, toolboxOption });

    const currentBlockPolygon = useMemo(
        () => polygons.find(({ id }) => id === blockEdited.id),
        [polygons, blockEdited.id]
    );

    const isBlockWithoutPolygon = blockEdited.id && !newPolygon && (!currentBlockPolygon || isCurrentPolygonDeleted);

    const removePolygonFromView = useCallback(() => {
        if (currentBlockPolygon && !isCurrentPolygonDeleted) {
            currentBlockPolygon.setMap(null);
            setIsCurrentPolygonDeleted(true);
        } else {
            newPolygon?.setMap(null);
            setNewPolygon(null);
        }
    }, [currentBlockPolygon, isCurrentPolygonDeleted, newPolygon]);

    const removePolygon = useCallback(
        blockId => {
            const polygon = polygons.find(({ id }) => id === blockId);
            polygon?.setMap(null);
            setPolygons(prevPolygons => prevPolygons.filter(({ id }) => id !== blockId));
        },
        [polygons]
    );

    const handleCancelPolygonSave = useCallback(() => {
        if (isCurrentPolygonDeleted) {
            currentBlockPolygon.setMap(map);
            setIsCurrentPolygonDeleted(false);
        }
        if (currentBlockPolygon) {
            // check if polygon was edited
            const coords = blockEdited.polygon;
            const coordsLatLng = coords?.map(({ lat, lng }) => new window.google.maps.LatLng(lat, lng));
            currentBlockPolygon.setPath(coordsLatLng);
        }
        if (newPolygon) {
            newPolygon.setMap(null);
            setNewPolygon(null);
        }
    }, [isCurrentPolygonDeleted, currentBlockPolygon, newPolygon, map, blockEdited.polygon]);

    const handleSavePolygon = useCallback(() => {
        const coords = getPolygonCoords(newPolygon || currentBlockPolygon);
        if (newPolygon) {
            if (isCurrentPolygonDeleted) {
                setPolygons(prevPolygons => [
                    ...prevPolygons.map(item => (item.id === blockEdited.id ? newPolygon : item)),
                ]);
                setIsCurrentPolygonDeleted(false);
            } else {
                setPolygons(prevPolygons => [...prevPolygons, newPolygon]);
            }
            setNewPolygon(null);
        }
        return coords;
    }, [newPolygon, currentBlockPolygon, isCurrentPolygonDeleted, blockEdited.id]);

    const createNewPolygon = useCallback(
        polygon => {
            polygon.setDraggable(true);
            polygon.setEditable(true);
            polygon.setOptions(COMPLETED_POLYGON_OPTIONS);
            const country = getPolygonCountry(polygon);
            const area = getPolygonArea(polygon, country);
            polygon.id = blockEdited.id;
            polygon.area = area.toFixed(2);
            if (area > 1) {
                setNewPolygon(polygon);
            } else {
                polygon.setDraggable(false);
                polygon.setEditable(false);
                polygon.setMap(null);
            }
        },
        [blockEdited.id, setNewPolygon]
    );

    const createNewAccessRoad = useCallback(
        line => {
            if (accessRoadRef.current) {
                accessRoadRef.current.setMap(null);
            }
            accessRoadRef.current = line;
            const lineCoords = line
                .getPath()
                .getArray()
                .reduce((acc, { lat, lng }) => [...acc, { lat: lat(), lng: lng() }], []);
            setValue('accessRoadCoordinates', lineCoords, { shouldDirty: true });
        },
        [setValue]
    );

    const removeAccessRoad = () => {
        accessRoadRef.current.setMap(null);
        accessRoadRef.current = null;
    };

    // create drawing manager
    useEffect(() => {
        if (drawingMode && map && !drawingManager) {
            createDrawingManager(map, drawingMode).then(manager => {
                setDrawingManager(manager);
            });
        }
    }, [drawingManager, map, drawingMode]);

    useEffect(() => {
        if (location?.state?.map && map) {
            map.setCenter({
                lat: location.state.map.lat,
                lng: location.state.map.lng,
            });
            map.setZoom(location.state.map.zoom);
        }
    }, [location, map]);

    useEffect(() => {
        if (drawingManager) {
            const { POLYGON, POLYLINE } = window.google.maps.drawing.OverlayType;
            if (drawingMode === POLYGON) {
                window.google.maps.event.addListener(drawingManager, 'polygoncomplete', createNewPolygon);
            } else if (drawingMode === POLYLINE) {
                window.google.maps.event.addListener(drawingManager, 'polylinecomplete', createNewAccessRoad);
            }
        }
        return () => {
            if (drawingManager) {
                ['polygoncomplete', 'polylinecomplete'].forEach(listener =>
                    window.google.maps.event.clearListeners(drawingManager, listener)
                );
            }
        };
    }, [drawingManager, createNewPolygon, toolboxOption, drawingMode, createNewAccessRoad]);

    useEffect(() => {
        if (drawingManager) {
            const { POLYGON, POLYLINE } = window.google.maps.drawing.OverlayType;
            switch (drawingMode) {
                case POLYGON:
                    if (isBlockWithoutPolygon) {
                        drawingManager?.setDrawingMode(POLYGON);
                    } else {
                        drawingManager?.setDrawingMode(null);
                        if (currentBlockPolygon) {
                            makePolygonEditable(currentBlockPolygon);
                        }
                    }
                    break;
                case POLYLINE:
                    drawingManager.setDrawingMode(POLYLINE);
                    break;
                default:
                    drawingManager.setDrawingMode(null);
                    polygons?.forEach(polygon => {
                        polygon.setDraggable(false);
                        polygon.setEditable(false);
                    });
                    break;
            }
        }
    }, [polygons, map, drawingManager, isBlockWithoutPolygon, currentBlockPolygon, drawingMode]);

    useEffect(() => {
        if (toolboxOption && map) {
            const cursor = getCursor(toolboxOption);
            map?.setOptions({ draggableCursor: cursor, cursor });
        }
    }, [toolboxOption, map]);

    useEffect(() => {
        if (!accessRoadCoordinates && accessRoadRef.current) {
            removeAccessRoad();
        }
    }, [accessRoadCoordinates]);

    useCreateInitialFigures({ map, setPolygons, accessRoadRef });

    return {
        polygons,
        isBlockWithoutPolygon,
        removePolygonFromView,
        setMap,
        map,
        newPolygon,
        handleCancelPolygonSave,
        handleSavePolygon,
        removePolygon,
        drawingManager,
    };
};

export default useDrawingMode;
