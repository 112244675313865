import { createAsyncAction } from '@beewise/utils';

export const SEND_CONF_DATA = createAsyncAction('SEND_CONF_DATA');
const UPDATE_CONF_DATA = createAsyncAction('UPDATE_CONF_DATA');
export const SAVE_CONF_DATA = 'SAVE_CONF_DATA';

export const saveConfData = data => ({
    type: SAVE_CONF_DATA,
    payload: data,
});

export const sendConfData = (body, resolver) => ({
    type: 'FETCH',
    params: {
        type: SEND_CONF_DATA.default,
        method: 'POST',
        url: '/conf',
        body,
        resolver,
    },
});

export const updateConfData = (body, id) => ({
    type: 'FETCH',
    params: {
        type: UPDATE_CONF_DATA.default,
        method: 'PATCH',
        url: `/conf/${id}`,
        body,
    },
});
