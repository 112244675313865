import React, { useMemo, useEffect, useRef, useCallback, memo } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import Button from '@beewise/button';
import PropTypes from 'prop-types';
import { usePrevious } from '@beewise/react-utils';
import { faCircleXmark } from '@fortawesome/pro-light-svg-icons';
import { useParams } from 'react-router-dom';
import { isUndefined } from 'lodash-es';
import getTreeList from 'components/reusables/Form/utils/getTreeList';
import IconButtonV2 from 'components/reusables/IconButtonV2';
import { getUnitsDefinitionFromCountry } from 'utils/isIsraeliRanch';
import useDebounceEffect from 'utils/hooks/useDebounceEffect';
import { VALIDATIONS, InputFormField, SelectFormField } from '@beewise/hook-form';
import useRecalculatePolygonArea from 'components/views/AdminView/views/BrokerRanch/hooks/useRecalculatePolygonArea';
import { ROOT_ERRORS, getRootLevelErrors } from 'components/reusables/Form/Errors';
import { getRequiredNumberOfHives } from 'utils';

const getYears = () => {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 31 }, (_, index) => currentYear - 30 + index);

    return years.map(year => ({ label: year, value: year }));
};

const yearsOptions = getYears();

const defaultValues = {
    id: '',
    name: '',
    totalArea: '',
    ratio: '',
    hivesAmount: '',
    year: undefined,
    trees: [],
};

const Block = ({ onClose, onSave, id, crop, block, isBlockWithoutPolygon, newPolygon, country, initialPolygon }) => {
    const didMountRef = useRef(false);
    const { id: isEditRanchMode } = useParams();
    const isEditBlockMode = !!block;
    const {
        control,
        handleSubmit,
        setValue,
        watch,
        setError,
        formState: { isDirty, errors },
    } = useForm({
        defaultValues: { ...(block || defaultValues), name: block ? block.name : id, id },
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'trees',
    });

    const header = isEditBlockMode ? 'Edit Block' : 'Create Block';
    const breadcrumb = isEditRanchMode ? 'Edit Ranch' : 'Create Ranch';

    const [totalArea, ratio] = watch(['totalArea', 'ratio']);

    const prevValues = usePrevious({ totalArea, ratio });

    const treeListOptions = useMemo(() => getTreeList(crop).map(({ value, text }) => ({ value, label: text })), [crop]);
    const addTreeVariety = () => append({ tree: '', percent: '' });

    const handleSave = useCallback(
        data => {
            if (isBlockWithoutPolygon) {
                return setError(ROOT_ERRORS.FORM_ERROR, {
                    type: 'required',
                    message: 'Please draw a polygon on the map',
                });
            }
            const { trees, ...rest } = data;
            const validTrees = trees?.filter(({ tree, percent }) => tree && percent);
            onSave({ ...rest, trees: validTrees });
        },
        [isBlockWithoutPolygon, onSave, setError]
    );

    useEffect(() => {
        if (didMountRef.current) {
            const percentPerField = Math.round(100 / fields.length);
            fields.forEach((field, index) => {
                setValue(`trees.${index}.percent`, percentPerField);
            });
        }
    }, [fields, setValue, isDirty]);

    useRecalculatePolygonArea({ initialPolygon, setValue, country, newPolygon, isBlockWithoutPolygon });

    useDebounceEffect(
        () => {
            if (
                !isUndefined(prevValues?.totalArea) &&
                !isUndefined(prevValues?.ratio) &&
                (totalArea !== prevValues?.totalArea || ratio !== prevValues?.ratio)
            ) {
                if (totalArea && ratio) {
                    const hivesAmount = getRequiredNumberOfHives(totalArea, ratio, country);
                    !Number.isNaN(hivesAmount) && setValue('hivesAmount', hivesAmount);
                } else {
                    setValue('hivesAmount', 0);
                }
            }
        },
        [totalArea, ratio, country, prevValues],
        1000
    );

    useEffect(() => {
        didMountRef.current = true;
    }, []);

    const formErrors = getRootLevelErrors(errors);

    return (
        <form className="left-panel-form">
            <div className="left-panel-container--scrollable">
                <div className="left-panel-container">
                    <div className="left-panel-container-header">
                        <div role="presentation" className="left-panel-breadcrumb" onClick={onClose}>
                            {breadcrumb} &gt;
                        </div>
                        <div className="left-panel-header">{header}</div>
                        <div className="left-panel-subheader">Fill in the information required about this block.</div>
                        {!!formErrors?.length && <div className="error error-block">{formErrors}</div>}
                    </div>
                    <InputFormField
                        name="name"
                        control={control}
                        label="Block name"
                        required
                        className="text-field--first"
                    />
                    <div className="left-panel-form-row">
                        <InputFormField
                            name="totalArea"
                            control={control}
                            label={getUnitsDefinitionFromCountry(country)}
                            required
                            pattern={VALIDATIONS.NUMBER}
                        />
                        <InputFormField
                            name="ratio"
                            control={control}
                            label="Ratio"
                            required
                            pattern={VALIDATIONS.NUMBER}
                        />
                    </div>
                    <InputFormField
                        name="hivesAmount"
                        control={control}
                        label="Hives"
                        required
                        type="number"
                        min={1}
                        pattern={VALIDATIONS.INTEGER_NUMBER}
                    />
                    <SelectFormField name="year" control={control} label="Year planted" options={yearsOptions} />
                    {fields.map((field, index) => (
                        <div className="left-panel-form-row" key={field.id}>
                            <SelectFormField
                                name={`trees.${index}.tree`}
                                control={control}
                                label="Tree"
                                options={treeListOptions}
                                className="col-two-3"
                            />
                            <InputFormField
                                name={`trees.${index}.percent`}
                                control={control}
                                className="col-one-3"
                                pattern={VALIDATIONS.NUMBER}
                                type="percentage"
                            />
                            <IconButtonV2 icon={faCircleXmark} onClick={() => remove(index)} className="icon-remove" />
                        </div>
                    ))}
                    <Button className="text-blue btn-add" onClick={addTreeVariety}>
                        + Add tree variety
                    </Button>
                </div>
            </div>

            <div className="left-panel-footer">
                <Button className="secondary-blue" onClick={onClose} shadow>
                    Cancel
                </Button>
                <Button className="primary-blue" onClick={handleSubmit(handleSave)} disabled={!isDirty} shadow>
                    Save Block
                </Button>
            </div>
        </form>
    );
};

Block.propTypes = {
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    id: PropTypes.string,
    crop: PropTypes.string,
    block: PropTypes.shape(),
    isBlockWithoutPolygon: PropTypes.bool,
    newPolygon: PropTypes.shape(),
    country: PropTypes.string,
    initialPolygon: PropTypes.shape(),
};

export default memo(Block);
