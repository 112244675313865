import dayjs from 'dayjs';
import constants from 'appConstants';
import {
    FETCH_RANCHES_NEW_FLOW,
    FETCH_RANCHES_NEW_FLOW_HIVES_AMOUNT,
    SELECT_CURRENT_ITEM,
    DESELECT_CURRENT_ITEM,
    DELETE_RANCHES_NEW_FLOW,
    MODIFY_RANCH_ORDERS,
    SEND_SMS_WITH_COORDINATES,
    SEND_RANCH_REPORT,
    FETCH_WEATHER_DATA,
    FETCH_GRAPH_DATA,
    FETCH_GROWERS_NEW_FLOW,
    FETCH_BEEKEEPERS_NEW_FLOW,
    FETCH_INSTALLERS_NEW_FLOW,
    DELETE_COMPANY_USER_BY_COMPANY_ID,
    CREATE_COMPANY_USER,
    UPDATE_COMPANY_USER,
    FETCH_COMPANY_BY_NAME,
    TOGGLE_COUNTRIES,
    REQUEST_RANCH_APPROVAL,
    FETCH_COMPANY_USERS,
} from '../actionTypes';

const companyTypeMap = {
    [constants.COMPANY_TYPES.GROWER]: FETCH_GROWERS_NEW_FLOW.default,
    [constants.COMPANY_TYPES.BEEKEEPER]: FETCH_BEEKEEPERS_NEW_FLOW.default,
    [constants.COMPANY_TYPES.INSTALLER]: FETCH_INSTALLERS_NEW_FLOW.default,
};

const generateQueryParams = (params = {}) => {
    const parts = Object.entries(params).reduce((acc, [key, value]) => {
        if (Array.isArray(value)) {
            return [...acc, ...value.map(val => `${key}=${val}`)];
        } else {
            return [...acc, `${key}=${value}`];
        }
    }, []);

    return parts.join('&');
};

export const fetchCompanyByName = name => ({
    type: 'FETCH',
    params: {
        type: FETCH_COMPANY_BY_NAME.default,
        url: `/companies/exists?name=${name}`,
        failureToastText: 'Error fetching company by name',
    },
});

export const fetchRanches = params => {
    const queryParams = generateQueryParams(params);
    const url = `/ranches${queryParams ? `?${queryParams}` : ''}`;

    return {
        type: 'FETCH',
        params: {
            type: FETCH_RANCHES_NEW_FLOW.default,
            url,
            failureToastText: 'Loading ranches error',
        },
    };
};

export const fetchHivesAmount = (params, resolver, rejector) => {
    const queryParams = generateQueryParams(params);
    const url = `/ranches/hives-amounts${queryParams ? `?${queryParams}` : ''}`;
    return {
        type: 'FETCH',
        params: {
            type: FETCH_RANCHES_NEW_FLOW_HIVES_AMOUNT.default,
            url,
            failureToastText: 'Loading hives amount error',
            resolver,
            rejector,
        },
    };
};

export const createCompanyUser = (data, resolver, rejector) => ({
    type: 'FETCH',
    params: {
        method: 'POST',
        type: CREATE_COMPANY_USER.default,
        url: '/companies/users',
        body: data,
        failureToastText: 'Cannot create company with user',
        toastText: 'Company with user successfully created',
        resolver,
        rejector,
    },
});

export const requestRanchApproval = (id, resolver, rejector) => ({
    type: 'FETCH',
    params: {
        method: 'POST',
        type: REQUEST_RANCH_APPROVAL.default,
        url: `/ranches/${id}/approvals`,
        failureToastText: 'Cannot send ranch approval request',
        toastText: 'Ranch approval request has been sent',
        resolver,
        rejector,
    },
});

export const updateCompanyUser = (companyId, user, resolver, rejector) => ({
    type: 'FETCH',
    params: {
        method: 'PUT',
        type: UPDATE_COMPANY_USER.default,
        url: `/companies/${companyId}/users`,
        body: user,
        failureToastText: 'Cannot update company with user',
        toastText: 'Company with user successfully updated',
        resolver,
        rejector,
    },
});

export const deleteRanchById = id => ({
    type: 'FETCH',
    params: {
        method: 'DELETE',
        type: DELETE_RANCHES_NEW_FLOW.default,
        url: `/ranches/${id}`,
        failureToastText: 'Deleting ranch error',
        toastText: 'Ranch successfully deleted',
    },
});

export const deleteCompanyUserByCompanyId = companyId => ({
    type: 'FETCH',
    params: {
        method: 'DELETE',
        type: DELETE_COMPANY_USER_BY_COMPANY_ID.default,
        url: `/companies/v2/${companyId}`,
        failureToastText: 'Deleting user error',
        toastText: 'User successfully deleted',
    },
});

export const sendSmsWithCoordinates = body => ({
    type: 'FETCH',
    params: {
        method: 'POST',
        type: SEND_SMS_WITH_COORDINATES.default,
        url: '/sms',
        body,
        failureToastText: 'Error sending sms',
        toastText: 'Sms message sent successfully',
    },
});

export const fetchCompaniesByType = (companyType, params) => {
    const queryParams = generateQueryParams({ ...params, userId: true });
    const url = `/companies/list?type=${companyType}${queryParams ? `&${queryParams}` : ''}`;

    return {
        type: 'FETCH',
        params: {
            method: 'GET',
            type: companyTypeMap[companyType],
            url,
            failureToastText: `Error fetching ${companyType}s`,
        },
    };
};

export const modifyRanchOrders = ({ ranchId, data }, resolver, rejector) => ({
    type: 'FETCH',
    params: {
        method: 'PATCH',
        type: MODIFY_RANCH_ORDERS.default,
        url: `/ranch-orders/ranch/${ranchId}`,
        body: data,
        resolver,
        rejector,
    },
});

export const sendRanchReport = (ranchId, body, resolver) => ({
    type: 'FETCH',
    params: {
        type: SEND_RANCH_REPORT.default,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        method: 'POST',
        url: `/ranch/${ranchId}/report`,
        body,
        resolver,
    },
});

export const fetchWeatherData = ranchId => ({
    type: 'FETCH',
    params: {
        type: FETCH_WEATHER_DATA.default,
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'GET',
        url: `/weather/${ranchId}`,
        failureToastText: 'Error fetching weather data',
    },
});

export const fetchGraphData = id => {
    const newestDate = dayjs.utc().startOf('day');
    const oldestDate = newestDate.subtract(2, 'month').startOf('day');
    const to = newestDate.format();
    const from = oldestDate.format();
    const idParam = id ? `&id=${id}` : '';

    return {
        type: 'FETCH',
        params: {
            type: FETCH_GRAPH_DATA.default,
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'GET',
            url: `/ranch/get-aggregated-ranches-content?attr[]=numBroodCells&attr[]=numHoneyCells&attr[]=numPollenCells&from=${from}&to=${to}${idParam}`,
            failureToastText: 'Error fetching ranch content',
        },
    };
};

export const toggleCountries = country => ({
    type: TOGGLE_COUNTRIES,
    country,
});

export const selectCurrentItem = item => ({
    type: SELECT_CURRENT_ITEM,
    item,
});

export const deselectCurrentItem = () => ({
    type: DESELECT_CURRENT_ITEM,
});

export const fetchCompanyUsers = companyId => ({
    type: 'FETCH',
    params: {
        type: FETCH_COMPANY_USERS.default,
        url: `/users?companyId=${companyId}&roles[]=farm_manager&roles[]=ranch_manager`,
    },
});
