export const COUNTRY_IL = 'il';
export const COUNTRY_US = 'us';
const UNIT_TYPE_AREA = 'area';
const UNIT_TYPE_LENGTH = 'length';
const UNIT_ACRE = 'acre';
const UNIT_DUNAM = 'dunam';
const UNIT_M2 = 'm2';
const UNIT_MI = 'mi';
const UNIT_M = 'm';

export default ranch => ranch?.country === COUNTRY_IL;

export const measureUnits = {
    [COUNTRY_IL]: {
        areaUnit: UNIT_DUNAM,
        area: 'Dunams',
        currency: 'ILS',
        distance: UNIT_M,
        pollinationArea: '321 m',
        temperature: '°C',
        temperatureRange: [5, 50],
    },
    [COUNTRY_US]: {
        areaUnit: UNIT_ACRE,
        area: 'Acres',
        currency: '$',
        distance: UNIT_MI,
        pollinationArea: '0.2 mi',
        temperature: '°F',
        temperatureRange: [30, 110],
    },
};

export const units = {
    [UNIT_ACRE]: { name: UNIT_ACRE, ratio: 4046.86, type: UNIT_TYPE_AREA },
    [UNIT_DUNAM]: { name: UNIT_DUNAM, ratio: 1e3, type: UNIT_TYPE_AREA },
    [UNIT_M2]: { name: UNIT_M2, ratio: 1, type: UNIT_TYPE_AREA },
    [UNIT_MI]: { name: UNIT_MI, ratio: 1609.34, type: UNIT_TYPE_LENGTH },
    [UNIT_M]: { name: UNIT_M, ratio: 1, type: UNIT_TYPE_LENGTH },
};

export const convertToCelsius = fahrenheit => (((fahrenheit - 32) * 5) / 9).toFixed(0);

export const convertValues = (value, fromUnit, toUnit) => {
    const sourceUnit = units[fromUnit];
    const destinationUnit = units[toUnit];

    if (!sourceUnit || !destinationUnit) {
        throw new Error(`No such unit: ${fromUnit} or ${toUnit}`);
    }
    if (sourceUnit.type !== destinationUnit.type) {
        throw new Error('Cannot convert between different measurement types');
    }

    return (value * sourceUnit.ratio) / destinationUnit.ratio;
};

export const getUnits = country => measureUnits[country] || measureUnits[COUNTRY_US];

export const getRatioHeader = country => (country === COUNTRY_IL ? 'Hive/Dunam Ratio' : 'Hive/Acre Ratio');

export const convertArea = (area, country, revert = false) => {
    if (country === COUNTRY_US) {
        return Number(area);
    }
    return revert ? convertValues(area, UNIT_DUNAM, UNIT_ACRE) : convertValues(area, UNIT_ACRE, UNIT_DUNAM);
};

// Only for distance in meters
export const convertDistance = (distance, country) => {
    if (country === COUNTRY_US) {
        return Math.round(convertValues(distance, UNIT_M, UNIT_MI) * 100) / 100;
    }
    return distance;
};

export const getUnitsDefinitionFromCountry = country => (country === COUNTRY_IL ? 'Dunams' : 'Acres');

export const getPlural = (singularName, quantity) => {
    const itemCount = Array.isArray(quantity) ? quantity.length : quantity;
    return `${singularName}${itemCount > 1 ? 's' : ''}`;
};
