import React, { useState, useMemo, useCallback, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '@beewise/button';
import TextField from '@beewise/text-field';
import DatePicker from 'react-datepicker';
import { faCircleLeft, faTimer } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import useForm from 'utils/hooks/useForm';
import SvgBackground from './SvgBackground';
import { sendConfData, saveConfData, updateConfData } from './actions';

import './Confrence.scss';

const filterPassedTime = time => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    const selectedDate10am = new Date(time).setHours(9);
    const selectedDate18pm = new Date(time).setHours(19);
    const selectedTime = selectedDate.getTime();
    return currentDate.getTime() < selectedTime && selectedTime > selectedDate10am && selectedTime < selectedDate18pm;
};

const initialFields = {
    company: '',
    totalAcres: '',
    name: '',
    email: '',
    contactNumber: '',
    scheduledTime: null,
};

const headers = [
    {
        leftSideHeader: 'Control your pollination with bees, robotics & AI',
        header: 'Create New Account',
        subheader: 'Set up a meeting to start planning your next pollination',
        buttonName: 'start',
    },
    {
        leftSideHeader: 'Control your pollination with bees, robotics & AI',
        header: 'Your Company Details',
        buttonName: 'Set up ranch',
    },
    {
        leftSideHeader: 'Your Ranch is all set',
        header: 'Schedule time with us',
        subheader: (
            <>
                Set up a meeting to start planning your next pollination
                <h5>
                    <FontAwesomeIcon icon={faTimer} />1 hour
                </h5>
            </>
        ),
        buttonName: 'Schedule meeting',
        skipButton: 'Skip',
    },
    {
        header: 'Thank You',
        subheader: 'Meeting was successfully scheduled',
        buttonName: 'Done',
    },
];
const Conference = ({ initStep = 0 }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [isTimeScheduleSkipped, setIsTimeScheduleSkipped] = useState(false);

    const companyData = useSelector(state => state.conference.companyData);
    const recordId = useSelector(state => state.conference.recordId);

    const [step, setStep] = useState(companyData.name && !location?.state?.cancelRedirect ? 2 : initStep);

    const goBack = useCallback(() => {
        if (step === 2 && companyData.ranchId) {
            navigate(`/ranch/${companyData.ranchId}`, {
                state: { prev: location.pathname },
            });
        } else {
            setStep(prev => prev - 1);
        }
        setIsTimeScheduleSkipped(false);
    }, [step, companyData.ranchId, navigate, location.pathname, setIsTimeScheduleSkipped]);

    const goForward = useCallback(() => setStep(prev => prev + 1), [setStep]);
    const handleSetIsTimeScheduleSkipped = useCallback(() => {
        setIsTimeScheduleSkipped(val => !val);
        goForward();
    }, [setIsTimeScheduleSkipped, goForward]);

    const { state, handleLocalReducerChange } = useForm({
        initialFields: recordId ? companyData : initialFields,
    });

    useEffect(() => {
        if (companyData.ranchId) {
            dispatch(sendConfData({ ...companyData, ranchId: companyData.ranchId }));
        }
    }, [companyData.ranchId, dispatch, companyData]);

    const refreshPageData = useCallback(() => {
        handleLocalReducerChange(null, initialFields, 'rerender_state');
        setStep(0);
        setIsTimeScheduleSkipped(false);
    }, [handleLocalReducerChange, setStep, setIsTimeScheduleSkipped]);

    const saveDataAndRedirectToRanch = useCallback(() => {
        dispatch(saveConfData(state));
        navigate('/ranch', {
            replace: true,
            state: { prev: location.pathname },
        });
    }, [state, navigate, location.pathname, dispatch]);

    const submitAndGoForward = useCallback(() => {
        dispatch(updateConfData({ scheduledTime: state.scheduledTime }, recordId));
        setStep(prevStep => prevStep + 1);
    }, [setStep, state.scheduledTime, recordId, dispatch]);

    const handleNameChange = useCallback(
        e => handleLocalReducerChange('name', e.target.value),
        [handleLocalReducerChange]
    );
    const handleEmailChange = useCallback(
        e => handleLocalReducerChange('email', e.target.value),
        [handleLocalReducerChange]
    );
    const handleContactNumberChange = useCallback(
        e => handleLocalReducerChange('contactNumber', e.target.value),
        [handleLocalReducerChange]
    );
    const handleCompanyChange = useCallback(
        e => handleLocalReducerChange('company', e.target.value),
        [handleLocalReducerChange]
    );
    const handleTotalAcres = useCallback(
        e => handleLocalReducerChange('totalAcres', e.target.value),
        [handleLocalReducerChange]
    );

    const handleChangeDate = useCallback(
        val => handleLocalReducerChange('scheduledTime', val),
        [handleLocalReducerChange]
    );

    const button = useMemo(() => {
        const buttonData = [
            {
                disabled: !state.name || !state.email || !state.contactNumber,
                onClick: goForward,
            },
            {
                disabled: !state.company,
                onClick: saveDataAndRedirectToRanch,
            },
            {
                disabled: !state.scheduledTime,
                onClick: submitAndGoForward,
            },
            {
                onClick: refreshPageData,
            },
        ];
        return (
            <Button onClick={buttonData[step].onClick} disabled={buttonData[step].disabled} className="btn-confirm">
                {headers[step].buttonName}
            </Button>
        );
    }, [step, refreshPageData, submitAndGoForward, goForward, saveDataAndRedirectToRanch, state]);

    const form = useMemo(() => {
        const { header, subheader } = headers[step];
        const inputData = [
            [
                {
                    label: 'Name',
                    value: state.name,
                    onChange: handleNameChange,
                },
                {
                    label: 'Email',
                    value: state.email,
                    onChange: handleEmailChange,
                },
                {
                    label: 'Contact number',
                    value: state.contactNumber,
                    onChange: handleContactNumberChange,
                },
            ],
            [
                {
                    label: 'Company',
                    value: state.company,
                    onChange: handleCompanyChange,
                },
                {
                    label: 'Total acres',
                    value: state.totalAcres,
                    onChange: handleTotalAcres,
                },
            ],
        ];

        const inputs = inputData[step]?.map(({ label, ...rest }) => (
            <Fragment key={label}>
                <div className="label">{label}</div>
                <TextField {...rest} />
            </Fragment>
        ));

        const time = dayjs(state.scheduledTime);

        const formContent = [
            inputs,
            inputs,
            <div>
                <h3>Select Date and Time</h3>
                <DatePicker
                    selected={state.scheduledTime}
                    onChange={handleChangeDate}
                    minDate={new Date()}
                    showTimeSelect
                    timeFormat="h:mm aa"
                    dateFormat="MMMM d, yyyy HH:mm"
                    timeIntervals={30}
                    inline
                    filterTime={filterPassedTime}
                />
            </div>,
            state.scheduledTime && <p>We are scheduled on {time.format('ddd, MMM D, YYYY h:mm A')}</p>,
        ];

        return (
            <>
                <h1 className={isTimeScheduleSkipped && 'middle'}>{header}</h1>
                <h2>{!isTimeScheduleSkipped && subheader}</h2>
                <div className={cx('conference-form-container', `step-${step}`)}>
                    {formContent[step]}
                    <div className="btn-container">
                        {headers[step].skipButton && (
                            <Button className="btn-default btn" onClick={handleSetIsTimeScheduleSkipped}>
                                {headers[step].skipButton}
                            </Button>
                        )}
                        {button}
                    </div>
                </div>
            </>
        );
    }, [
        step,
        state,
        handleNameChange,
        handleEmailChange,
        handleContactNumberChange,
        handleCompanyChange,
        handleTotalAcres,
        button,
        handleChangeDate,
        handleSetIsTimeScheduleSkipped,
        isTimeScheduleSkipped,
    ]);

    return (
        <div className="conference">
            <div className="wrapper">
                <section className="name">
                    <img
                        src="https://images.squarespace-cdn.com/content/5d657d979e6bb20001475e97/1568146278525-8OYUKM9TAOXI7S50D02K/beewise-header-alt.png"
                        alt="beewise-header"
                    />
                    <h1>{headers[step].leftSideHeader}</h1>
                    <SvgBackground />
                    {!!step && (
                        <div className="btn-back" onClick={goBack} role="presentation">
                            <FontAwesomeIcon icon={faCircleLeft} />
                            &nbsp;Back
                        </div>
                    )}
                </section>
                <section className="conference-form">{form}</section>
            </div>
        </div>
    );
};

Conference.propTypes = {
    initStep: PropTypes.number,
};

export default Conference;
