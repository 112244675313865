import { shallowEqual, useSelector } from 'react-redux';

export default name => {
    const settings = useSelector(state => state.app.appSettings, shallowEqual);

    if (name) {
        return settings[name];
    }

    return settings;
};
