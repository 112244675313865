import { useMemo } from 'react';
import constants from 'appConstants';
import { getCategories as getHardcodedCategories } from 'data';

const {
    REPORTS: { CORRECTION_FACTOR },
} = constants;

const getInitialCategories = () => ({
    '>10 Frames': { hives: 0, percent: 0 },
    '8-10 Frames': { hives: 0, percent: 0 },
    '6-8 Frames': { hives: 0, percent: 0 },
    '<6 Frames': { hives: 0, percent: 0 },
});

const useChartData = (dataModel, isHardcodedData, ranchId) =>
    useMemo(() => {
        if (isHardcodedData) {
            return getHardcodedCategories(ranchId);
        }
        if (!dataModel || !Object.keys(dataModel)?.length) {
            return;
        }

        const categories = getInitialCategories();

        let totalHives = 0;

        Object.keys(dataModel).forEach(key => {
            const hivesAverageBeeAmount = dataModel[key];
            hivesAverageBeeAmount.forEach(beeFrames => {
                let categoryKey;
                const correctedFrames = beeFrames * CORRECTION_FACTOR;

                if (correctedFrames > 10) {
                    categoryKey = '>10 Frames';
                } else if (correctedFrames > 7) {
                    categoryKey = '8-10 Frames';
                } else if (correctedFrames > 5) {
                    categoryKey = '6-8 Frames';
                } else {
                    categoryKey = '<6 Frames';
                }
                categories[categoryKey].hives += 1;
                totalHives += 1;
            });
        });

        if (totalHives > 0) {
            Object.keys(categories).forEach(category => {
                categories[category].percent = (categories[category].hives / totalHives) * 100;
            });
        }

        const chartData = Object.keys(categories).map(category => ({
            name: category,
            percent: +categories[category].percent.toFixed(),
            hives: categories[category].hives,
        }));

        return chartData;
    }, [dataModel, isHardcodedData, ranchId]);

export default useChartData;
