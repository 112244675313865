import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loading, arrayOfObjectsShallowEqual, shallowEqual } from '@beewise/react-utils';
import { VariableSizeList as List } from 'react-window';
import { identifyRanchByItem } from 'utils/identifyRanchByItem';
import AutoSizer from 'react-virtualized-auto-sizer';
import ContentLoader from 'react-content-loader';
import { useWindowResize } from './useWindowResize';
import useVirtualizedList from './useVirtualizedList';
import RanchItem from './RanchItem';
import './RanchesList.scss';
import { selectCurrentItem, deselectCurrentItem } from '../../../../actions';
import { getSelectedItem, getRanches } from '../../../../selectors';
import { FETCH_RANCHES_NEW_FLOW } from '../../../../actionTypes';

const RanchListContentLoader = () => {
    const dummyRanchCount = 11;
    return (
        <div className="ranches-list">
            <ContentLoader height="100%" width="100%" speed={1} backgroundColor="#EEEEEE" foregroundColor="#BDBDBD">
                {[...Array(dummyRanchCount)].map((_, index) => (
                    <React.Fragment key={index}>
                        <rect x="36" y={index * 60 + 10} rx="3" ry="3" width="20%" height="20" />
                        <rect x="calc(100% - 36px - 60%)" y={index * 60 + 10} rx="3" ry="3" width="60%" height="20" />
                    </React.Fragment>
                ))}
            </ContentLoader>
        </div>
    );
};

const RanchesList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const selectedItem = useSelector(getSelectedItem, shallowEqual);
    const [openRanchId, setOpenRanchId] = useState(null);
    const ranches = useSelector(getRanches, arrayOfObjectsShallowEqual);
    const windowWidth = useWindowResize();

    const { setSize, getItemSize, listRef } = useVirtualizedList({ ranches, selectedItem });

    const handleAccordionToggle = useCallback(
        ranchId => {
            if (openRanchId === ranchId) {
                setOpenRanchId(null);
                dispatch(deselectCurrentItem());
            } else {
                setOpenRanchId(ranchId);
                dispatch(selectCurrentItem(ranches.find(ranch => ranch.id === ranchId)));
            }
        },
        [dispatch, openRanchId, ranches]
    );

    useEffect(() => {
        if (selectedItem) {
            const ranch = identifyRanchByItem({ item: selectedItem, ranches });

            if (ranch && ranch.id !== openRanchId) {
                handleAccordionToggle(ranch.id);
            }
        } else if (openRanchId !== null) {
            setOpenRanchId(null);
        }
    }, [handleAccordionToggle, openRanchId, ranches, selectedItem]);

    return (
        !!ranches?.length && (
            <div className="ranches-list">
                <AutoSizer>
                    {({ height, width }) => (
                        <List
                            itemCount={ranches.length}
                            itemSize={getItemSize}
                            height={height}
                            width={width}
                            ref={listRef}
                            itemData={{
                                ranches,
                                openRanchId,
                                handleAccordionToggle,
                                navigate,
                                windowWidth,
                                setSize,
                            }}
                        >
                            {RanchItem}
                        </List>
                    )}
                </AutoSizer>
            </div>
        )
    );
};

export default loading(FETCH_RANCHES_NEW_FLOW.default, RanchListContentLoader)(RanchesList);
