import { createAsyncAction } from '@beewise/utils';

export const GET_ALL_COMPANIES = createAsyncAction('GET_ALL_COMPANIES');
export const UPDATE_ADMIN_COMPANY = createAsyncAction('UPDATE_ADMIN_COMPANY');
export const FETCH_USER_SETTINGS = createAsyncAction('FETCH_USER_SETTINGS');
export const FETCH_UPDATE_USER_SETTINGS = createAsyncAction('FETCH_UPDATE_USER_SETTINGS');
export const INVITE_USERS = createAsyncAction('INVITE_USERS');
export const FETCH_COMPANY_USERS = createAsyncAction('FETCH_COMPANY_USERS');
export const FETCH_UPDATE_MILESTONES = createAsyncAction('FETCH_UPDATE_MILESTONES');
export const UPDATE_COMPANY_LAST_VISITED = createAsyncAction('UPDATE_COMPANY_LAST_VISITED');
export const FETCH_CREATE_FARM = createAsyncAction('FETCH_CREATE_FARM');
export const FETCH_ALL_COMPANY_TYPES = createAsyncAction('FETCH_ALL_COMPANY_TYPES');
export const FETCH_APP_SETTINGS = createAsyncAction('FETCH_APP_SETTINGS');
