import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import constants from 'appConstants';
import { fetchRanches, fetchHivesAmount, selectCurrentItem, fetchCompaniesByType } from '../../actions';
import { getRanches, getSelectedItem } from '../../selectors';
import ControlsView from './ControlsView';
import MapView from './MapView';
import styles from './AdminDashboard.module.scss';
import useMap from './hooks/useMap';

const AdminDashboard = () => {
    const dispatch = useDispatch();
    const ranches = useSelector(getRanches, arrayOfObjectsShallowEqual);
    const selectedItem = useSelector(getSelectedItem);
    const countriesToShow = useSelector(state => state.adminDashboard.countriesToShow);

    useEffect(() => {
        const params = { country: countriesToShow };
        dispatch(fetchCompaniesByType(constants.COMPANY_TYPES.BEEKEEPER, params));
        dispatch(fetchCompaniesByType(constants.COMPANY_TYPES.INSTALLER, params));
        dispatch(fetchHivesAmount(params));
        dispatch(fetchRanches(params));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countriesToShow]);

    const handleSelectCurrentItem = useCallback(item => {
        dispatch(selectCurrentItem(item));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSearch = useCallback(params => {
        dispatch(fetchRanches(params));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const mapConfig = {
        handleSelectCurrentItem,
        selectedItem,
        ranches,
    };

    const { map, setMap, isBhomeView, handleMapChange } = useMap(mapConfig);

    return (
        <div className={styles.app}>
            <div className={styles.content}>
                <ControlsView onSearch={handleSearch} map={map} />
                <MapView
                    handleSelectCurrentItem={handleSelectCurrentItem}
                    map={map}
                    setMap={setMap}
                    isBhomeView={isBhomeView}
                    handleMapChange={handleMapChange}
                />
            </div>
        </div>
    );
};

export default AdminDashboard;
