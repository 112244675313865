import React from 'react';
import Menu from '@beewise/menu';
import getPolygonCenterCoordinates from 'utils/getPolygonCenterCoordinates';
import { noop } from 'lodash-es';
import LoadingZoneIcon from '../LoadingZoneIcon';
import PinMarker, { getPinEntity } from '../PinMarker';
import BlockLabel from '../BlockLabel';

export const renderBlockLabels = ({ blocks }) =>
    blocks?.map(({ polygon, name }) => {
        const centerCoordinates = getPolygonCenterCoordinates(polygon);
        return <BlockLabel {...centerCoordinates} key={JSON.stringify(centerCoordinates)} name={name} />;
    });

export const renderPins = ({ pins, onClick = noop, name, getMenuItems }) =>
    pins?.map(pin => (
        <PinMarker
            key={pin.id}
            marker={pin}
            lat={pin.lat}
            lng={pin.lng}
            note={pin.note}
            type={pin.type}
            onClick={onClick}
            name={`${getPinEntity(pin.type)} for ${name} ranch`}
            {...(getMenuItems ? { menuItems: getMenuItems({ lat: pin.lat, lng: pin.lng, entity: 'pin' }) } : {})}
        />
    ));

const renderMapAdditionalMarkers = ({ ranches, getMenuItems }) =>
    ranches.flatMap(({ blocks, pins, loadingZoneCoordinates, name }) => {
        const labelMarkers = renderBlockLabels({ blocks });
        const pinMarkers = renderPins({ pins, name, getMenuItems });
        const markers = [...labelMarkers, ...pinMarkers];

        if (loadingZoneCoordinates) {
            const coords = { lat: loadingZoneCoordinates.lat, lng: loadingZoneCoordinates.lng };
            const loadingZoneIcon = getMenuItems ? (
                <Menu
                    key={JSON.stringify(coords)}
                    options={getMenuItems({ ...coords, entity: 'loading zone' })}
                    customComponent={<LoadingZoneIcon />}
                    {...coords}
                />
            ) : (
                <LoadingZoneIcon {...coords} key={JSON.stringify(coords)} />
            );

            markers.push(loadingZoneIcon);
        }

        return markers;
    });

export default renderMapAdditionalMarkers;
