import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { camelCase } from 'lodash-es';
import Card from 'components/reusables/Card';
import constants from 'appConstants';
import TimelineGraph from 'components/reusables/TimelineGraph';
import { formatDate } from 'utils/formatters';
import './DeliveryStatus.scss';

const statuses = {
    [constants.ORDER_STATUSES.CONFIRMED]: {
        label: 'Your hives allocation is confirmed',
    },
    [constants.ORDER_STATUSES.SCHEDULED]: {
        label: 'Your hives are secured and will be arriving soon to the ranch',
    },
    [constants.ORDER_STATUSES.IN_TRANSIT]: {
        label: 'Your hives are secured and will be arriving soon to the ranch',
    },
    [constants.ORDER_STATUSES.STARTED_PLACEMENT]: {
        label: 'Your hives are gradually being deployed to best fit to the needs of the ranch',
        substatus: true,
    },
    [constants.ORDER_STATUSES.DELIVERED]: {
        label: 'All your hives were successfully delivered',
    },
};

const DeliveryStatus = ({ order = {} }) => {
    const name = order.status.toUpperCase().replaceAll('_', ' ');

    const tooltipText = useMemo(
        () =>
            Object.keys(statuses).reduce((acc, key) => {
                if (!statuses[key].substatus) {
                    const date = order[camelCase(`status_${key}_date`)];
                    const enhancedNaming = key.replace('_', ' ');
                    acc.push(date ? `${enhancedNaming} (Set on ${formatDate(date)})` : enhancedNaming);
                }
                return acc;
            }, []),
        [order]
    );

    const activeIndex = useMemo(() => {
        const index = Object.keys(statuses).indexOf(order.status);
        if (statuses[order.status].substatus) {
            return { index, substatus: true };
        }
        return { index };
    }, [order.status]);

    const deliveryTime = useMemo(() => {
        const deliveryDate = formatDate(order.deliveryDate);
        const removalDate = formatDate(order.removalDate);
        const finalDeliveryDate = formatDate(order.finalDeliveryDate);
        const estimatedDeliveryDate = formatDate(order.estimatedDeliveryDate);
        if (deliveryDate || finalDeliveryDate || estimatedDeliveryDate) {
            if (order.status === constants.ORDER_STATUSES.CONFIRMED) {
                return `Estimated delivery: ${estimatedDeliveryDate}`;
            } else if (
                [constants.ORDER_STATUSES.SCHEDULED, constants.ORDER_STATUSES.IN_TRANSIT].includes(order.status)
            ) {
                return `Scheduled delivery: ${deliveryDate}`;
            } else if (order.status === constants.ORDER_STATUSES.STARTED_PLACEMENT) {
                const finalDeliveryDate = formatDate(order.finalDeliveryDate);
                return (
                    <>
                        Last Scheduled delivery: {finalDeliveryDate || deliveryDate}{' '}
                        {removalDate ? <div className="margined-top">Estimated removal: {removalDate}</div> : ''}
                    </>
                );
            }
            return `Estimated removal: ${removalDate}`;
        }
    }, [order.deliveryDate, order.removalDate, order.finalDeliveryDate, order.estimatedDeliveryDate, order.status]);

    return (
        <Card title="Delivery status">
            <h1 className="delivery-header">{name}</h1>
            <TimelineGraph activeIndex={activeIndex} points={4} dashed large tooltipText={tooltipText} />
            <p>{statuses?.[order.status]?.label}</p>
            {deliveryTime && <p>{deliveryTime}</p>}
        </Card>
    );
};

DeliveryStatus.propTypes = {
    order: PropTypes.shape({}).isRequired,
};

export default DeliveryStatus;
