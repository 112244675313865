import { useCallback, useEffect, useState } from 'react';
import { distanceToPolygon } from 'distance-to-polygon';
import storage from 'local-storage-fallback';
import { MARKER_TYPE } from '@beewise/constants/build/lib';
import constants from 'appConstants';
import { hivesAmountKey } from 'utils';
import { modalController } from 'components/reusables/ModalManager/modalController';
import { MODALS } from 'components/reusables/ModalManager/config';
import { noop } from 'lodash-es';
import { getPolygonCoords } from './useDrawingMode';

const { TOOLBOX_MODE, DEFAULT_CURSOR_URL, PIN_TYPES } = constants;

const pinTypesBatched = [constants.PIN_TYPES.PIN, constants.PIN_TYPES.GATE, constants.PIN_TYPES.ROAD_BLOCK];

const getPolygonWithMinDistance = ({ lat, lng, polygons }) => {
    let minDistance = Number.POSITIVE_INFINITY;
    let minDistanceId = null;

    polygons.forEach(polygon => {
        const coords = getPolygonCoords(polygon).map(({ lat, lng }) => [lat, lng]);
        const distance = distanceToPolygon([lat, lng], coords);
        if (distance < minDistance) {
            minDistance = distance;
            minDistanceId = polygon.id;
        }
    });

    return minDistanceId;
};

const setDefaultCursor = () => {
    const div = document.querySelector('[aria-label="Map"]');
    if (div) {
        div.style.cursor = 'default';
    }
};

const clearAllListeners = ({ map, polygons }) => {
    window.google.maps.event.clearListeners(map, 'click');
    polygons.forEach(polygon => window.google.maps.event.clearListeners(polygon, 'click'));
};

const addClickListener = (target, event, listener) => {
    window.google.maps.event.clearListeners(target, event);
    window.google.maps.event.addListener(target, event, listener);
};

const useMarkerMode = ({ toolboxOption, blockEdited, locations, pins, setValue, polygons, map, setCustomCursor }) => {
    const [draggedMarkerId, setDraggedMarkerId] = useState(null);

    const createLocation = useCallback(
        location => setValue('locations', [...locations, location], { shouldDirty: true, shouldTouch: true }),
        [locations, setValue]
    );

    const removeLocation = useCallback(
        id =>
            setValue(
                'locations',
                locations.filter(location => location.id !== id),
                { shouldDirty: true, shouldTouch: true }
            ),
        [locations, setValue]
    );

    const updateLocation = useCallback(
        (index, location) => setValue(`locations.${index}`, location, { shouldDirty: true, shouldTouch: true }),
        [setValue]
    );

    const createPin = useCallback(
        pin => setValue('pins', [...pins, pin], { shouldDirty: true, shouldTouch: true }),
        [pins, setValue]
    );

    const removePin = useCallback(
        id =>
            setValue(
                'pins',
                pins.filter(pin => pin.id !== id),
                { shouldDirty: true, shouldTouch: true }
            ),
        [pins, setValue]
    );

    const updatePin = useCallback(
        (index, pin) => setValue(`pins.${index}`, pin, { shouldDirty: true, shouldTouch: true }),
        [setValue]
    );

    const handleEditLocation = useCallback(
        marker => {
            const { id, name, hivesAmount = constants.DEFAULT_HIVES_AMOUNT, type, note } = marker;
            const locationIndex = locations.findIndex(location => location.id === id);
            modalController.set({
                name: MODALS.CREATE_LOCATION,
                props: {
                    name,
                    isWithDeleteButton: type === MARKER_TYPE.EMPTY_LOCATION,
                    hivesAmount,
                    note,
                    isEdit: true,
                    updateLocation: item => updateLocation(locationIndex, { ...marker, ...item }),
                    removeLocation: () => removeLocation(id),
                },
            });
        },
        [locations, removeLocation, updateLocation]
    );

    const handlePinModal = useCallback(
        marker => {
            const { id, note, type } = marker;
            const pinIndex = pins.findIndex(pin => pin.id === id);

            modalController.set({
                name: MODALS.PIN_MODAL,
                props: {
                    note,
                    isEdit: true,
                    type,
                    removePin: () => removePin(id),
                    updatePin: item => updatePin(pinIndex, { ...marker, ...item }),
                },
            });
        },
        [pins, removePin, updatePin]
    );

    const markerClickListener = useCallback(
        (marker, e) => {
            e.stopPropagation();
            if (toolboxOption === TOOLBOX_MODE.MOVE && !pinTypesBatched.includes(marker.type)) {
                handleEditLocation(marker);
            } else if (pinTypesBatched.includes(marker.type)) {
                handlePinModal(marker);
            }
        },
        [handleEditLocation, handlePinModal, toolboxOption]
    );

    const getName = useCallback(
        name => {
            const nameExists = locations.some(location => String(location.name) === String(name));
            if (nameExists) {
                return getName(name + 1);
            }
            return name.toString();
        },
        [locations]
    );

    const handleMarkerRelease = useCallback(
        (index, marker, { lat, lng }) => {
            setDraggedMarkerId(null);
            const blockId = getPolygonWithMinDistance({ lat, lng, polygons });
            updateLocation(index, { ...marker, blockId, lat, lng });
            setCustomCursor(false);
            setDefaultCursor();
        },
        [polygons, updateLocation, setCustomCursor]
    );

    const handleDragStart = (marker, index) => e => {
        e.preventDefault();
        setCustomCursor(true);
        setDraggedMarkerId(marker.id);
        map.setOptions({ draggable: false });
        const onMouseUp = event => {
            handleMarkerRelease(index, marker, { lat: event.latLng.lat(), lng: event.latLng.lng() });
            map.setOptions({ draggable: true, cursor: DEFAULT_CURSOR_URL });
            window.google.maps.event.clearListeners(map, 'mouseup');
            polygons.forEach(poly => window.google.maps.event.clearListeners(poly, 'mouseup'));
        };

        map.addListener('mouseup', onMouseUp);
        polygons.forEach(poly => {
            poly.addListener('mouseup', onMouseUp);
        });
    };

    const handleAddLoadingZoneMarker = useCallback(
        ({ latLng }) => {
            const lat = latLng.lat();
            const lng = latLng.lng();
            setValue('loadingZoneCoordinates', { lat, lng }, { shouldDirty: true, shouldTouch: true });
        },
        [setValue]
    );

    const addLocationMarker = useCallback(
        blockId =>
            ({ latLng }) => {
                const locationsLength = locations.length;
                const lat = latLng.lat();
                const lng = latLng.lng();
                const name = getName(locationsLength + 1);
                const hivesAmount = storage.getItem(hivesAmountKey) || constants.DEFAULT_HIVES_AMOUNT;
                const note = '';
                const id = `${constants.NEW_LOCATION_PREFIX}${lat}${lng}`;
                const locationExists = locations.some(item => item.id === id);

                if (locationExists) {
                    return;
                }

                // if no blockId - find nearest polygon
                if (!blockId) {
                    blockId = getPolygonWithMinDistance({ lat, lng, polygons });
                }

                const location = {
                    lat,
                    lng,
                    name,
                    hivesAmount,
                    type: MARKER_TYPE.EMPTY_LOCATION,
                    id,
                    blockId,
                    note,
                };
                createLocation(location);
                modalController.set({
                    name: MODALS.CREATE_LOCATION,
                    props: {
                        ...location,
                        removeLocation: () => removeLocation(locationsLength),
                        updateLocation: item => updateLocation(locationsLength, { ...location, ...item }),
                    },
                });
            },
        [createLocation, locations, getName, polygons, removeLocation, updateLocation]
    );

    const addPinMarker = useCallback(
        type =>
            ({ latLng }) => {
                const pinsLength = pins.length;
                const lng = latLng.lng();
                const lat = latLng.lat();
                const id = `${constants.NEW_PIN_PREFIX}${lat}${lng}`;
                const pinExists = pins.some(item => item.id === id);
                const note = '';

                if (pinExists) {
                    return;
                }

                const pin = {
                    lat,
                    lng,
                    id,
                    note,
                    type,
                };

                createPin(pin);

                modalController.set({
                    name: MODALS.PIN_MODAL,
                    props: {
                        ...pin,
                        removePin: () => removePin(id),
                        updatePin: item => updatePin(pinsLength, { ...pin, ...item }),
                    },
                });
            },
        [createPin, pins, removePin, updatePin]
    );

    const handleMarkerMouseEnter = () => (!draggedMarkerId ? setCustomCursor(false) : noop());

    useEffect(() => {
        if (!map) {
            return;
        }

        const handleMapClick = handler => {
            addClickListener(map, 'click', handler);
            polygons.forEach(polygon => addClickListener(polygon, 'click', handler));
        };

        const isEditMode = !!blockEdited.id;
        switch (toolboxOption) {
            case TOOLBOX_MODE.PLACE_LOCATION:
                if (!isEditMode) {
                    handleMapClick(addLocationMarker());
                }
                break;
            case TOOLBOX_MODE.MARK_PINS:
                if (!isEditMode) {
                    handleMapClick(addPinMarker(PIN_TYPES.PIN));
                }
                break;
            case TOOLBOX_MODE.MARK_GATES:
                if (!isEditMode) {
                    handleMapClick(addPinMarker(PIN_TYPES.GATE));
                }
                break;
            case TOOLBOX_MODE.MARK_ROAD_BLOCKS:
                if (!isEditMode) {
                    handleMapClick(addPinMarker(PIN_TYPES.ROAD_BLOCK));
                }
                break;
            case TOOLBOX_MODE.MARK_LOADING_ZONE:
                handleMapClick(handleAddLoadingZoneMarker);
                break;
            default:
                clearAllListeners({ map, polygons });
        }

        return () => {
            if (!map) {
                return;
            }
            window.google.maps.event.clearListeners(map, 'click');
            polygons.forEach(polygon => window.google.maps.event.clearListeners(polygon, 'click'));
        };
    }, [blockEdited.id, map, toolboxOption, polygons, addPinMarker, addLocationMarker, handleAddLoadingZoneMarker]);

    return { markerClickListener, draggedMarkerId, handleDragStart, addLocationMarker, handleMarkerMouseEnter };
};

export default useMarkerMode;
