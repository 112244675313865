import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useToggle } from '@beewise/react-utils';
import { shallowEqual, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const redirectUsersList = [
    'conf_shlomki@beewise.ag',
    'conf_ofir@beewise.ag',
    'conf_tai@beewise.ag',
    'conf_will@beewise.ag',
    'conf_fritz@beewise.ag',
    'conf_eliyah@beewise.ag',
    'conf_nati@beewise.ag',
    'conf_greg@beewise.ag',
    'conf_ryan@beewise.ag',
    'conf_mars@beewise.ag',
];

const ConfProvider = ({ children }) => {
    const [isInitialRender, toggleInitialRender] = useToggle(true);
    const user = useSelector(state => state.app.user, shallowEqual);
    const navigate = useNavigate();

    useEffect(() => {
        if (user && user.email && redirectUsersList.includes(user.email) && isInitialRender) {
            toggleInitialRender();
            navigate('/conf');
        }
    }, [isInitialRender, navigate, toggleInitialRender, user]);

    return <div>{children}</div>;
};

ConfProvider.propTypes = {
    children: PropTypes.node,
};

export default ConfProvider;
