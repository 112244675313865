import './wdyr';
import 'es6-symbol/implement';
import React from 'react';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';

import { createRoot } from 'react-dom/client';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { BeewiseToastContainer } from '@beewise/toast';
import isBetween from 'dayjs/plugin/isBetween';

import { ModalManager } from 'components/reusables/ModalManager';
import store from './store';
import Routes from './router';
import initSentry from './sentry';

import './App.scss';

dayjs.extend(utc).extend(isBetween);

initSentry();

const App = () => (
    <Provider store={store}>
        <div id="app" className="app">
            <Routes />
            <BeewiseToastContainer position="bottom-center" />
            <ModalManager />
        </div>
    </Provider>
);

const AppWithProfiler = Sentry.withProfiler(App);

const root = createRoot(document.getElementById('root'));
root.render(<AppWithProfiler />);
