import { useEffect, useRef } from 'react';
import { getPolygonArea } from 'utils';

const useRecalculatePolygonArea = ({ setValue, newPolygon, country, initialPolygon, isBlockWithoutPolygon }) => {
    const draggingRef = useRef(false);

    useEffect(() => {
        if (newPolygon) {
            const area = getPolygonArea(newPolygon, country);
            setValue('totalArea', area.toFixed(2), { shouldDirty: true });
        }
    }, [newPolygon, setValue, country]);

    const handleSetArea = (polygon, country, setValue) => {
        const area = getPolygonArea(polygon, country);
        setValue('totalArea', area.toFixed(2), { shouldDirty: true });
    };

    useEffect(() => {
        const currentPolygon = newPolygon || initialPolygon;

        if (currentPolygon) {
            currentPolygon.addListener('dragstart', () => {
                draggingRef.current = true;
            });

            currentPolygon.addListener('dragend', () => {
                draggingRef.current = false;
                handleSetArea(currentPolygon, country, setValue);
            });
            currentPolygon.getPaths().forEach(path => {
                // works when we move middle points which are not edge corners yet
                window.google.maps.event.addListener(path, 'insert_at', () => {
                    handleSetArea(currentPolygon, country, setValue);
                });
                // works when we drag edge corners of the figure of move the whole figure
                window.google.maps.event.addListener(path, 'set_at', () => {
                    // do not fire area calculation if perimeter is moved
                    if (!draggingRef.current) {
                        handleSetArea(currentPolygon, country, setValue);
                    }
                });
            });
        }
        return () => {
            const currentPolygon = newPolygon || initialPolygon;
            if (currentPolygon) {
                window.google.maps.event.clearInstanceListeners(currentPolygon);
                currentPolygon.getPaths().forEach(path => {
                    window.google.maps.event.clearInstanceListeners(path);
                });
            }
        };
    }, [newPolygon, initialPolygon, country, setValue]);

    useEffect(() => {
        if (isBlockWithoutPolygon) {
            setValue('totalArea', 0, { shouldDirty: true });
        }
    }, [isBlockWithoutPolygon, setValue]);
};

export default useRecalculatePolygonArea;
