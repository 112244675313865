import { useDispatch, useSelector } from 'react-redux';
import { capitalize } from 'lodash-es';
import React from 'react';
import { modalController } from 'components/reusables/ModalManager/modalController';
import { MODALS } from 'components/reusables/ModalManager/config';
import constants from 'appConstants';
import { RowMenu } from 'components/reusables/ManageGridWrapper';
import {
    createCompanyUser,
    deleteCompanyUserByCompanyId,
    fetchCompaniesByType,
    updateCompanyUser,
} from '../../actions';

const defaultColumns = [
    {
        field: 'companyName',
        headerName: 'Company',
        flex: 1,
        valueGetter: ({ row }) => row.companyName || '-',
    },
    {
        field: 'username',
        headerName: 'Contact',
        flex: 1,
        valueGetter: ({ row }) => row.contact?.username || '-',
    },
    {
        field: 'email',
        headerName: 'Email',
        flex: 1,
        valueGetter: ({ row }) => row.contact?.email || '-',
    },
    {
        field: 'phone',
        headerName: 'Phone number',
        flex: 1,
        valueGetter: ({ row }) => row.contact?.phone || '-',
    },
    {
        field: 'address',
        headerName: 'Address',
        flex: 1,
        valueGetter: ({ row }) => row.address || '-',
    },
];

const extraBeekeeperColumns = [
    {
        field: 'hivesAmount',
        headerName: 'Available Hives',
        flex: 1,
        valueGetter: ({ row }) => row.companyData?.hivesAmount || '-',
    },
    {
        field: 'assignedHives',
        headerName: 'Assigned Hives',
        flex: 1,
        valueGetter: ({ row }) => row?.assignedHives || '-',
    },
    {
        field: 'isInstaller',
        headerName: 'Is installer',
        flex: 1,
        valueGetter: ({ row }) => (row.typeNames.includes('installer') ? 'Yes' : 'No'),
    },
];

const useGridColInit = ({ type }) => {
    const dispatch = useDispatch();
    const countriesToShow = useSelector(state => state.adminDashboard.countriesToShow);
    const defaultParams = { country: countriesToShow };

    const handleFetchCompany = (queryParams = {}) => fetchCompaniesByType(type, { ...defaultParams, ...queryParams });

    const handleEdit = row =>
        modalController.set({
            name: MODALS.ADMIN_DASHBOARD_USER_MODAL,
            props: {
                onConfirm: data => {
                    dispatch(updateCompanyUser(row.id, data));
                },
                entity: {
                    ...row,
                    isInstaller: row.typeNames.includes('installer').toString(),
                    isVisibleToAll: row.companyData?.isVisibleToAll
                        ? JSON.parse(row.companyData?.isVisibleToAll)
                        : false,
                    hivesAmount: row.companyData?.hivesAmount,
                },
                header: `Edit ${capitalize(type)}`,
                text: `Fill in the information of the ${type}.`,
                type,
            },
        });

    const handleAdd = () =>
        modalController.set({
            name: MODALS.ADMIN_DASHBOARD_USER_MODAL,
            props: {
                onConfirm: ({ isInstaller, ...rest }) => {
                    dispatch(
                        createCompanyUser(
                            {
                                ...rest,
                                type: [type, ...(isInstaller ? ['installer'] : [])],
                            },
                            () => dispatch(handleFetchCompany())
                        )
                    );
                },
                header: `Add ${capitalize(type)}`,
                text: `Fill in the information of the ${type}.`,
                type,
            },
        });

    const handleDelete = row =>
        modalController.set({
            name: MODALS.UNIVERSAL_TEXT_MODAL,
            props: {
                onConfirm: () => dispatch(deleteCompanyUserByCompanyId(row.id)),
                text: `Are you sure you want to delete this ${type}? This action cannot be undone.`,
            },
        });

    const columns = [
        ...defaultColumns,
        ...(type === constants.COMPANY_TYPES.BEEKEEPER ? extraBeekeeperColumns : []),
        {
            field: 'menu',
            headerName: '',
            width: '10',
            renderCell: ({ row }) => <RowMenu row={row} handleEdit={handleEdit} handleDelete={handleDelete} />,
        },
    ];

    return {
        columns,
        handleFetchCompany,
        handleAdd,
    };
};

export default useGridColInit;
