const INITIAL_POLYGON_OPTIONS = {
    strokeColor: '#fff',
    strokeWeight: 2,
    fillColor: 'transparent',
    editable: true,
    draggable: true,
    clickable: true,
};

export const COMPLETED_POLYGON_OPTIONS = {
    ...INITIAL_POLYGON_OPTIONS,
    strokeColor: '#feba12',
};

const POLYLINE_OPTIONS = {
    strokeColor: '#4392F1',
    strokeWeight: 4,
    editable: false,
};

export const createDrawingManager = async (map, drawingMode) => {
    const { DrawingManager } = await window.google.maps.importLibrary('drawing');

    const manager = new DrawingManager();

    const isOptionExists = Object.values(window.google.maps.drawing.OverlayType).includes(drawingMode);

    manager.setOptions({
        drawingMode: isOptionExists ? drawingMode : window.google.maps.drawing.OverlayType.POLYGON,
        polygonOptions: INITIAL_POLYGON_OPTIONS,
        polylineOptions: POLYLINE_OPTIONS,
        drawingControl: false,
        drawingControlOptions: {
            position: window.google.maps.ControlPosition.BOTTOM_CENTER,
            drawingModes: [
                window.google.maps.drawing.OverlayType.POLYGON,
                window.google.maps.drawing.OverlayType.POLYLINE,
            ],
        },
    });

    manager.setMap(map);
    return manager;
};
