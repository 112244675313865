import PropTypes from 'prop-types';

export const RanchType = PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    country: PropTypes.string,
    crop: PropTypes.string,
    totalArea: PropTypes.number,
    quality: PropTypes.number,
    requiredHivesAmount: PropTypes.number,
    showPotential: PropTypes.bool,
    loadingZoneCoordinates: PropTypes.shape({}),
    growerName: PropTypes.string,
    companyId: PropTypes.number,
    contact: PropTypes.shape({}),
    ranchManager: PropTypes.shape({}),
    blocks: PropTypes.arrayOf(PropTypes.shape({})),
    locations: PropTypes.arrayOf(PropTypes.shape({})),
    orders: PropTypes.arrayOf(PropTypes.shape({})),
    lat: PropTypes.number,
    lng: PropTypes.number,
});
