import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { noop } from 'lodash-es';
import Button from '@beewise/button';
import { InputFormField } from '@beewise/hook-form';
import ForgotPasswordSentMessage from '../ForgotPasswordSentMessage/ForgotPasswordSentMessage';
import { isEmailValid } from '../../utils/utils';
import AuthFormContainer from '../AuthFormContainer/AuthFormContainer';

export const defaultValues = {
  email: '',
  password: ''
};

const SignInForm = ({
  onSubmit = noop,
  onForgotPassword = noop,
  forgotPasswordSent = false,
  error = '',
  forgotPasswordError = '',
  title = ''
}) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({ defaultValues });

  const email = watch('email');

  const handleForgotPassword = () => {
    if (!email || !isEmailValid(email)) {
      return;
    }
    onForgotPassword(email);
  };
  return (
    <AuthFormContainer title={title}>
      <div className="form-container">
        <h3 className="form-header">{forgotPasswordSent ? 'Reset Password' : 'Log In'}</h3>
        {forgotPasswordSent ? (
          <ForgotPasswordSentMessage email={email} handleForgotPassword={handleForgotPassword} />
        ) : (
          <form className="form-block" onSubmit={handleSubmit(onSubmit)}>
            <InputFormField
              name="email"
              control={control}
              required
              validate={isEmailValid}
              placeholder="Email"
              size="normal"
              label="Email"
              error={errors.email ? 'Please enter a valid email.' : forgotPasswordError}
            />
            <InputFormField
              name="password"
              control={control}
              required
              type="password"
              placeholder="Password"
              size="normal"
              label="Password"
              error={error}
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  onSubmit();
                }
              }}
            />
            <div
              className="forgot-password-toggler"
              role="presentation"
              onClick={handleForgotPassword}
            >
              Forgot Password?
            </div>
            <Button className="btn primary-blue form-button" type="submit">
              Log In
            </Button>
          </form>
        )}
      </div>
    </AuthFormContainer>
  );
};

SignInForm.propTypes = {
  onSubmit: PropTypes.func,
  onForgotPassword: PropTypes.func,
  forgotPasswordSent: PropTypes.bool,
  error: PropTypes.string,
  forgotPasswordError: PropTypes.string,
  title: PropTypes.string
};

export default SignInForm;
