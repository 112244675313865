import React, { useCallback, useMemo, memo } from 'react';
import {
    faCircleMinus,
    faHand,
    faLocationDot,
    faMapLocationDot,
    faRoute,
    faTrash,
    faForklift,
    faNote,
    faDungeon,
    faRoadBarrier,
} from '@fortawesome/pro-light-svg-icons';
import PropTypes from 'prop-types';
import Tooltip from '@beewise/tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquarePlus } from '@fortawesome/pro-regular-svg-icons';
import constants from 'appConstants';
import IconButton from 'components/reusables/IconButtonV2';

import SearchBox from 'components/reusables/Map/components/SearchBox';
import { modalController } from 'components/reusables/ModalManager/modalController';
import { MODALS } from 'components/reusables/ModalManager/config';

const { TOOLBOX_MODE } = constants;

const getToolboxOptions = ({ loadingZoneCoordinates, accessRoadCoordinates }) => ({
    [TOOLBOX_MODE.REMOVE_POLYGON]: { icon: faTrash, label: 'Remove polygon' },
    [TOOLBOX_MODE.MOVE]: { icon: faHand, label: 'Move' },
    [TOOLBOX_MODE.AUTOPLACE]: { icon: faMapLocationDot, label: 'Autoplace' },
    [TOOLBOX_MODE.PLACE_LOCATION]: { icon: faLocationDot, label: 'Place' },
    [TOOLBOX_MODE.REMOVE_ALL_LOCATIONS]: { icon: faCircleMinus, label: 'Remove all' },
    [TOOLBOX_MODE.MARK_LOADING_ZONE]: {
        icon: faForklift,
        label: `${loadingZoneCoordinates ? 'Unmark' : 'Mark'} loading zone`,
    },
    [TOOLBOX_MODE.MARK_ACCESS_ROAD]: {
        icon: faRoute,
        label: `${accessRoadCoordinates ? 'Unmark' : 'Mark'} access road`,
    },
    [TOOLBOX_MODE.MARK_PINS]: {
        icon: faNote,
        label: 'Pin note to map',
    },
    [TOOLBOX_MODE.MARK_PINS]: {
        icon: faNote,
        label: 'Pin note to map',
    },
    [TOOLBOX_MODE.MARK_GATES]: {
        icon: faDungeon,
        label: 'Pin gate to map',
    },
    [TOOLBOX_MODE.MARK_ROAD_BLOCKS]: {
        icon: faRoadBarrier,
        label: 'Pin road block to map',
    },
});

const getToolboxOptionsArray = condition =>
    Object.entries(getToolboxOptions(condition)).map(([key, value]) => ({ ...value, name: key }));

const getDisabledStatus = ({ blockEdited, name, polygons }) => {
    switch (name) {
        case TOOLBOX_MODE.REMOVE_POLYGON:
            return !blockEdited.id;
        case TOOLBOX_MODE.REMOVE_ALL_LOCATIONS:
        case TOOLBOX_MODE.MARK_LOADING_ZONE:
        case TOOLBOX_MODE.MARK_ACCESS_ROAD:
        case TOOLBOX_MODE.AUTOPLACE:
        case TOOLBOX_MODE.MOVE:
        case TOOLBOX_MODE.PLACE_LOCATION:
        case TOOLBOX_MODE.MARK_PINS:
        case TOOLBOX_MODE.MARK_GATES:
        case TOOLBOX_MODE.MARK_ROAD_BLOCKS:
            return Boolean(!polygons.length || blockEdited.id);
        default:
            return false;
    }
};

const MapToolboxOptions = ({
    toolboxOption,
    handleSetToolboxOption,
    removePolygonFromView,
    blockEdited,
    polygons,
    map,
    blocks,
    setValue,
    loadingZoneCoordinates,
    accessRoadCoordinates,
    addLocationMarker,
    drawingManager,
}) => {
    const handleRemovePerimeter = useCallback(() => {
        // required to remove incomplete polygon
        if (drawingManager) {
            const { POLYGON } = window.google.maps.drawing.OverlayType;
            drawingManager?.setDrawingMode(POLYGON);
        }
        modalController.set({
            name: MODALS.REMOVE_CONFIRMATION,
            props: {
                onConfirm: removePolygonFromView,
                header: 'Remove Perimeter',
                info: 'Are you sure you want to remove perimeter?',
            },
        });
    }, [removePolygonFromView, drawingManager]);

    const handleRemoveAllDrops = useCallback(() => {
        modalController.set({
            name: MODALS.REMOVE_CONFIRMATION,
            props: {
                onConfirm: () => setValue('locations', [], { shouldDirty: true }),
                header: 'Remove All Drops',
                info: 'Are you sure you want to remove all drops?',
            },
        });
    }, [setValue]);

    const handleAutoPlaceDrops = useCallback(() => {
        modalController.set({
            name: MODALS.AUTO_PLACE,
            props: {
                onConfirm: locations => setValue('locations', locations, { shouldDirty: true }),
                header: 'Auto place drops on ranch',
                blocks,
            },
        });
    }, [blocks, setValue]);

    const handleMarkLoadingZone = useCallback(() => {
        if (loadingZoneCoordinates) {
            setValue('loadingZoneCoordinates', null, { shouldDirty: true });
        }
        handleSetToolboxOption(TOOLBOX_MODE.MARK_LOADING_ZONE)();
    }, [loadingZoneCoordinates, setValue, handleSetToolboxOption]);

    const handleMarkAccessRoad = useCallback(() => {
        if (accessRoadCoordinates) {
            setValue('accessRoadCoordinates', null, { shouldDirty: true });
        }
        handleSetToolboxOption(TOOLBOX_MODE.MARK_ACCESS_ROAD)();
    }, [accessRoadCoordinates, setValue, handleSetToolboxOption]);

    const handlers = {
        [TOOLBOX_MODE.REMOVE_POLYGON]: handleRemovePerimeter,
        [TOOLBOX_MODE.REMOVE_ALL_LOCATIONS]: handleRemoveAllDrops,
        [TOOLBOX_MODE.AUTOPLACE]: handleAutoPlaceDrops,
        [TOOLBOX_MODE.MARK_LOADING_ZONE]: handleMarkLoadingZone,
        [TOOLBOX_MODE.MARK_ACCESS_ROAD]: handleMarkAccessRoad,
    };

    const toolboxOptionsArray = useMemo(
        () => getToolboxOptionsArray({ loadingZoneCoordinates, accessRoadCoordinates }),
        [loadingZoneCoordinates, accessRoadCoordinates]
    );

    const isActive = name => {
        if (name === TOOLBOX_MODE.MARK_LOADING_ZONE && loadingZoneCoordinates) {
            return false;
        }
        if (name === TOOLBOX_MODE.MARK_ACCESS_ROAD && accessRoadCoordinates) {
            return false;
        }
        return toolboxOption === name;
    };

    const customIcon = <FontAwesomeIcon icon={faSquarePlus} className="add-icon" onClick={addLocationMarker()} />;

    return (
        <div className="map-menu">
            {toolboxOptionsArray.map(({ icon, name, label }) => (
                <Tooltip position="bottom" content={label} key={name}>
                    <IconButton
                        icon={icon}
                        onClick={handlers[name] || handleSetToolboxOption(name)}
                        disabled={getDisabledStatus({ blockEdited, name, polygons })}
                        isActive={isActive(name)}
                    />
                </Tooltip>
            ))}
            {map && (
                <SearchBox
                    map={map}
                    placeholder="Search location"
                    customIcon={customIcon}
                    isCustomIconShown={!!blocks?.length && !blockEdited?.id}
                />
            )}
        </div>
    );
};

MapToolboxOptions.propTypes = {
    toolboxOption: PropTypes.string,
    handleSetToolboxOption: PropTypes.func.isRequired,
    removePolygonFromView: PropTypes.func.isRequired,
    blockEdited: PropTypes.shape(),
    polygons: PropTypes.arrayOf(PropTypes.shape()),
    map: PropTypes.shape(),
    setValue: PropTypes.func,
    blocks: PropTypes.arrayOf(PropTypes.shape()),
    loadingZoneCoordinates: PropTypes.shape({}),
    accessRoadCoordinates: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.arrayOf(PropTypes.shape({}))]),
    addLocationMarker: PropTypes.func.isRequired,
    drawingManager: PropTypes.shape(),
};

export default memo(MapToolboxOptions);
