import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash-es';
import { useForm } from 'react-hook-form';
import Button from '@beewise/button';
import { InputFormField } from '@beewise/hook-form';
import { isPasswordValid } from '../../utils/utils';
import AuthFormContainer from '../AuthFormContainer/AuthFormContainer';

const defaultValues = {
  username: '',
  password: '',
  confirmPassword: ''
};

const ForgotPasswordForm = ({ onSubmit = noop, error = '', title = '' }) => {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm({ defaultValues });

  return (
    <AuthFormContainer title={title}>
      <div className="form-container">
        <h3 className="form-header">Setup your password</h3>
        <p className="form-info">
          Create a strong password with a mix of letters, numbers, and symbols.
        </p>
        <form className="form-block" onSubmit={handleSubmit(onSubmit)}>
          <InputFormField
            name="username"
            control={control}
            required="Full Name is required"
            placeholder="Full Name"
            size="normal"
            label="Username"
            error={errors.username ? errors.username.message : error}
          />
          <InputFormField
            name="password"
            control={control}
            required="Password is required"
            validate={(value) => isPasswordValid(value) || 'The password is not strong enough'}
            type="password"
            placeholder="Password"
            size="normal"
            label="Password"
            error={errors.password ? errors.password.message : error}
          />
          <InputFormField
            name="confirmPassword"
            control={control}
            required
            validate={(value, { password }) => value === password || 'Passwords do not match.'}
            type="password"
            placeholder="Re-enter Password"
            size="normal"
            label="Confirm Password"
            error={errors.confirmPassword ? errors.confirmPassword.message : ''}
          />
          <Button className="btn primary-blue form-button" type="submit">
            Done
          </Button>
        </form>
      </div>
    </AuthFormContainer>
  );
};

ForgotPasswordForm.propTypes = {
  onSubmit: PropTypes.func,
  error: PropTypes.string,
  title: PropTypes.string
};
export default ForgotPasswordForm;
