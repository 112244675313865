import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

const Card = ({ children, title, items }) => {
    const list = useMemo(
        () =>
            items?.map(({ name, value }) => (
                <div className="card-item" key={name}>
                    <div className="card-name">{name}</div>
                    <div className="card-value">{value}</div>
                </div>
            )),
        [items]
    );

    return (
        <>
            {title && <div className="subheader">{title}</div>}
            <div className="card">
                {list}
                {children}
            </div>
        </>
    );
};

export default Card;

Card.propTypes = {
    children: PropTypes.node,
    items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape(), PropTypes.node])),
    title: PropTypes.string,
};
