import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Menu from '@beewise/menu';
import Button from '@beewise/button';
import { debounce } from 'lodash-es';
import SearchableGrid from '@beewise/searchable-grid';
import { arrayOfObjectsShallowEqual } from '@beewise/react-utils';
import './ManageGridWrapper.scss';

export const RowMenu = ({ row, handleEdit, handleDelete }) => {
    const isDeleteDisabled = !!row.ranchIds?.length;
    return (
        <Menu
            position="bottom rigth"
            options={[
                {
                    label: 'Edit',
                    onClick: () => handleEdit(row),
                },
                {
                    label: 'Delete',
                    onClick: () => handleDelete(row),
                    isDisabled: isDeleteDisabled,
                    ...(isDeleteDisabled ? { tooltip: 'Entity cannot be deleted because it has ranches' } : {}),
                },
            ]}
        />
    );
};

RowMenu.propTypes = {
    row: PropTypes.shape({
        companyId: PropTypes.number,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        mobilePermissions: PropTypes.shape({
            installer: PropTypes.bool,
        }),
        companyData: PropTypes.shape({
            hivesAmount: PropTypes.number,
        }),
        ranchIds: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])),
    }),
    handleEdit: PropTypes.func,
    handleDelete: PropTypes.func,
};

const ManageGridWrapper = ({
    title,
    description,
    addButtonText,
    fetchData,
    dataSelector,
    columns,
    handleAdd,
    isLoadingSelector,
    ...props
}) => {
    const dispatch = useDispatch();
    const gridData = useSelector(dataSelector, arrayOfObjectsShallowEqual);
    const isLoading = useSelector(isLoadingSelector);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedFetch = useCallback(
        debounce(query => {
            dispatch(fetchData({ search: query }));
        }, 500),
        [dispatch, fetchData]
    );

    useEffect(() => {
        dispatch(fetchData());
        return () => debouncedFetch.cancel();
    }, [dispatch, fetchData, debouncedFetch]);

    return (
        <div className="admin-tables">
            <div className="manage-tables">
                <div>
                    <h3 className="manage-tables-header">{title}</h3>
                    <p className="manage-tables-text">{description}</p>
                </div>
                <div>
                    <Button className="primary-blue" onClick={handleAdd} shadow>
                        {addButtonText}
                    </Button>
                </div>
            </div>
            <div className="table-container">
                <SearchableGrid
                    columns={columns}
                    rows={gridData}
                    onSearch={debouncedFetch}
                    className="searchable-grid"
                    isSearchHidden={false}
                    sx={{
                        height: '100%',
                        width: '100%',
                        '& .MuiDataGrid-main': { fontFamily: 'Poppins', fontSize: '14px' },
                        '& .MuiDataGrid-withBorderColor': { border: 0 },
                        '& .MuiDataGrid-columnHeaderTitleContainer': {
                            padding: '16px',
                            fontWeight: '500',
                        },
                        '& .MuiDataGrid-cellContent': { padding: '16px', fontWeight: '100' },
                    }}
                    pagination
                    disableColumnSelector
                    disableDensitySelector
                    loading={isLoading}
                    {...props}
                />
            </div>
        </div>
    );
};

ManageGridWrapper.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    handleAdd: PropTypes.func,
    addButtonText: PropTypes.string,
    fetchData: PropTypes.func,
    dataSelector: PropTypes.func,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            field: PropTypes.string,
            headerName: PropTypes.string,
            flex: PropTypes.number,
            valueGetter: PropTypes.func,
            width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            renderCell: PropTypes.func,
        })
    ),
    isLoadingSelector: PropTypes.func,
};

export default ManageGridWrapper;
