import React from 'react';
import PropTypes from 'prop-types';
import Button from '@beewise/button';
import { ROUTE_PATHS } from 'router/constants';
import { getUnitsDefinitionFromCountry, convertArea } from 'utils/isIsraeliRanch';
import Row from './Row';

const RanchInfoColumn = ({ ranch, navigate }) =>
    !!ranch && (
        <div className="ranches-info-column">
            <h3 className="ranches-info-header">Ranch Info</h3>
            <Row primaryText="Company" secondaryText={ranch.growerName ?? ranch.companyName} />
            <Row
                primaryText={getUnitsDefinitionFromCountry(ranch.country)}
                secondaryText={convertArea(ranch.totalArea, ranch.country).toFixed()}
            />
            <Row primaryText="Number of drops" secondaryText={ranch?.locations?.length ?? '0'} />
            {ranch.quality && <Row primaryText="Quality" secondaryText={ranch.quality} />}
            <Row primaryText="Crop" secondaryText={ranch.crop} />
            {ranch.ranchManager?.name && <Row primaryText="Manager" secondaryText={ranch.ranchManager?.name} />}
            <Button key="edit-ranch" className="text-blue" onClick={() => navigate(`${ROUTE_PATHS.RANCH}/${ranch.id}`)}>
                edit ranch details
            </Button>
        </div>
    );

RanchInfoColumn.propTypes = {
    ranch: PropTypes.shape(),
    navigate: PropTypes.func.isRequired,
};

export default RanchInfoColumn;
