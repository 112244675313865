import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import constants from 'appConstants';
import Ranch from './Ranch';
import Block from './Block';
import { generateUniqueIdForNewItem } from './utils';

import './LeftPanel.scss';

const LeftPanel = ({
    blockEdited,
    setBlockEdited,
    handleCancelPolygonSave,
    handleSavePolygon,
    removePolygon,
    isBlockWithoutPolygon,
    newPolygon,
    polygons,
    drawingManager,
    form,
}) => {
    const { watch, setValue } = form;

    const [blocks, locations, country] = watch(['blocks', 'locations', 'country']);

    const fullBlockObject = useMemo(
        () => blockEdited.id && blocks.find(({ id }) => id === blockEdited.id),
        [blocks, blockEdited.id]
    );
    const fullPolygonObject = useMemo(
        () => blockEdited.id && polygons.find(({ id }) => id === blockEdited.id),
        [polygons, blockEdited.id]
    );

    const handleOpenAddBlockForm = useCallback(() => {
        const id = generateUniqueIdForNewItem(blocks, constants.NEW_BLOCK_PREFIX);
        setBlockEdited({ id });
    }, [blocks, setBlockEdited]);

    const handleCloseBlockForm = useCallback(() => {
        setBlockEdited({});
        // eslint-disable-next-line
    }, []);

    const handleCancelBlockForm = useCallback(() => {
        // required to remove incomplete polygon
        if (drawingManager) {
            const { POLYGON } = window.google.maps.drawing.OverlayType;
            drawingManager?.setDrawingMode(POLYGON);
        }
        handleCancelPolygonSave();
        handleCloseBlockForm();
    }, [drawingManager, handleCancelPolygonSave, handleCloseBlockForm]);

    const handleSaveBlock = useCallback(
        block => {
            const blockIndex = blocks?.findIndex(({ id }) => id === block.id);
            const isBlockExist = blockIndex !== -1;
            const coords = handleSavePolygon();
            if (isBlockExist) {
                setValue(`blocks.${blockIndex}`, { ...block, polygon: coords }, { shouldDirty: true });
            } else {
                setValue('blocks', [...blocks, { ...block, polygon: coords }], { shouldDirty: true });
            }
            handleCloseBlockForm();
        },
        [blocks, setValue, handleSavePolygon, handleCloseBlockForm]
    );

    const handleRemoveBlock = useCallback(
        blockId => {
            setValue(
                'blocks',
                blocks.filter(({ id }) => id !== blockId),
                { shouldDirty: true }
            );
            removePolygon(blockId);
            setValue(
                'locations',
                locations.filter(location => location.blockId !== blockId),
                { shouldDirty: true }
            );
        },
        [locations, blocks, removePolygon, setValue]
    );

    return (
        <div className="left-panel">
            {blockEdited.id ? (
                <Block
                    onClose={handleCancelBlockForm}
                    onSave={handleSaveBlock}
                    id={blockEdited.id}
                    crop={watch('crop')}
                    block={fullBlockObject}
                    isBlockWithoutPolygon={isBlockWithoutPolygon}
                    newPolygon={newPolygon}
                    initialPolygon={fullPolygonObject}
                    country={country}
                />
            ) : (
                <Ranch
                    handleOpenAddBlockForm={handleOpenAddBlockForm}
                    setBlockEdited={setBlockEdited}
                    handleRemoveBlock={handleRemoveBlock}
                    form={form}
                />
            )}
        </div>
    );
};

LeftPanel.propTypes = {
    blockEdited: PropTypes.shape(),
    setBlockEdited: PropTypes.func,
    handleCancelPolygonSave: PropTypes.func,
    handleSavePolygon: PropTypes.func,
    removePolygon: PropTypes.func,
    isBlockWithoutPolygon: PropTypes.bool,
    newPolygon: PropTypes.shape(),
    polygons: PropTypes.arrayOf(PropTypes.shape()),
    drawingManager: PropTypes.shape(),
    form: PropTypes.shape().isRequired,
};

export default LeftPanel;
