import { useEffect, useCallback } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateMapBounds } from 'components/reusables/Map/components/Map/utils';
import { getSelectedItem } from '../../../selectors';

const createPolyline = ({ map, coords }) => {
    const polyline = new window.google.maps.Polyline({
        path: coords,
        strokeColor: '#4392F1',
        strokeWeight: 4,
        editable: false,
    });
    polyline.setMap(map);
    return polyline;
};

const createPolygon = ({ map, polygon, blockId }) => {
    const polygonCreated = new window.google.maps.Polygon({
        capturing: true,
        paths: polygon,
        strokeColor: '#feba12',
        strokeWeight: 2,
        fillColor: 'transparent',
        editable: false,
        draggable: false,
        clickable: true,
        cursor: null,
    });
    // FIXME: changing map's cursor on hover polygon with 'default' from here
    polygonCreated.setOptions({
        draggableCursor: 'default',
        cursor: 'default',
    });
    polygonCreated.setMap(map);
    polygonCreated.id = blockId;
    return polygonCreated;
};

const useCreateInitialFigures = ({ map, setPolygons, accessRoadRef }) => {
    const { id: isEditMode } = useParams();

    const selectedRanch = useSelector(getSelectedItem, shallowEqual);

    const processRanchBlocks = useCallback(
        ranch =>
            ranch.blocks.reduce(
                (acc, { polygon, id }) => {
                    acc.flattenedCoords.push(...polygon);
                    const polygonCreated = createPolygon({ map, polygon, blockId: id });
                    acc.polygons.push(polygonCreated);
                    return acc;
                },
                { polygons: [], flattenedCoords: [] }
            ),
        [map]
    );

    useEffect(() => {
        if (isEditMode && selectedRanch && map) {
            const { polygons, flattenedCoords } = processRanchBlocks(selectedRanch);

            setPolygons(polygons);
            updateMapBounds(map, flattenedCoords);

            if (selectedRanch.accessRoadCoordinates) {
                accessRoadRef.current = createPolyline({ map, coords: selectedRanch.accessRoadCoordinates });
            }
        }
    }, [accessRoadRef, isEditMode, map, processRanchBlocks, selectedRanch, setPolygons]);
};

export default useCreateInitialFigures;
