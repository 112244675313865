import {
    FETCH_RANCHES_NEW_FLOW,
    FETCH_RANCHES_NEW_FLOW_HIVES_AMOUNT,
    SELECT_CURRENT_ITEM,
    DESELECT_CURRENT_ITEM,
    DELETE_RANCHES_NEW_FLOW,
    MODIFY_RANCH_ORDERS,
    FETCH_WEATHER_DATA,
    FETCH_GRAPH_DATA,
    FETCH_GROWERS_NEW_FLOW,
    FETCH_BEEKEEPERS_NEW_FLOW,
    FETCH_INSTALLERS_NEW_FLOW,
    DELETE_COMPANY_USER_BY_COMPANY_ID,
    UPDATE_COMPANY_USER,
    FETCH_COMPANY_BY_NAME,
    TOGGLE_COUNTRIES,
    FETCH_COMPANY_USERS,
} from '../actionTypes';
import { UPDATE_RANCH, CREATE_RANCH, FETCH_RANCH } from '../views/BrokerRanch/actionTypes';

const initialState = {
    ranches: [],
    hivesAmount: {},
    selectedItem: null,
    reportWeatherData: [],
    reportGraphData: [],
    growers: [],
    beekeepers: [],
    installers: [],
    existingCompanyOnCreation: null,
    countriesToShow: [],
    companyUsers: [],
};

const calculateTotalHiveAmount = (items = []) => items.reduce((sum, entity) => sum + (entity.hivesAmount || 0), 0);

const populateRanchesDataModel = ranches =>
    ranches.map(ranch => ({
        ...ranch,
        totalAssignedHives: calculateTotalHiveAmount(ranch.orders),
        totalPlannedHives: calculateTotalHiveAmount(ranch.locations),
    }));

const updateRanches = (ranches, updatedRanch) =>
    ranches.map(ranch => (ranch.id === updatedRanch.id ? updatedRanch : ranch));

const updateObjectInArrayById = (array = [], item) => {
    if (array.some(({ id }) => id === item?.id)) {
        return array.map(i => {
            if (i.id !== item.id) {
                return i;
            }

            return {
                ...i,
                ...item,
            };
        });
    } else {
        return [...array];
    }
};

const adminDashboard = (state = initialState, action) => {
    const { type, data, item, country } = action;
    const { ranches } = state;

    switch (type) {
        case FETCH_RANCHES_NEW_FLOW.success:
            return {
                ...state,
                ranches: populateRanchesDataModel(data.ranches),
            };
        case FETCH_RANCHES_NEW_FLOW_HIVES_AMOUNT.success:
            return {
                ...state,
                hivesAmount: data,
            };
        case SELECT_CURRENT_ITEM:
            return {
                ...state,
                selectedItem: item,
            };
        case DESELECT_CURRENT_ITEM:
            return {
                ...state,
                selectedItem: null,
            };
        case CREATE_RANCH.success:
            return {
                ...state,
                ranches: populateRanchesDataModel([data.ranch, ...ranches]),
                selectedItem: data.ranch,
            };
        case UPDATE_RANCH.success:
            return {
                ...state,
                ranches: updateRanches(ranches, data),
                selectedItem: data,
            };
        case FETCH_RANCH.success:
            return {
                ...state,
                selectedItem: data.ranch,
            };
        case DELETE_RANCHES_NEW_FLOW.success:
            return {
                ...state,
                ranches: ranches.filter(({ id }) => data.id !== id),
            };
        case MODIFY_RANCH_ORDERS.success:
            return {
                ...state,
                ranches: populateRanchesDataModel(updateRanches(ranches, data)),
                selectedItem: data,
            };
        case FETCH_WEATHER_DATA.success:
            return {
                ...state,
                reportWeatherData: data,
            };
        case FETCH_GRAPH_DATA.success:
            return {
                ...state,
                reportGraphData: data,
            };
        case FETCH_GROWERS_NEW_FLOW.success:
            return {
                ...state,
                growers: data,
            };
        case FETCH_BEEKEEPERS_NEW_FLOW.success:
            return {
                ...state,
                beekeepers: data,
            };
        case FETCH_INSTALLERS_NEW_FLOW.success:
            return {
                ...state,
                installers: data,
            };
        case DELETE_COMPANY_USER_BY_COMPANY_ID.success:
            return {
                ...state,
                growers: state.growers.filter(({ id }) => data?.result && data?.ids[0] !== id),
                beekeepers: state.beekeepers.filter(({ id }) => data?.result && data?.ids[0] !== id),
                installers: state.installers.filter(({ id }) => data?.result && data?.ids[0] !== id),
            };
        case UPDATE_COMPANY_USER.success:
            return {
                ...state,
                growers: updateObjectInArrayById(state.growers, data),
                beekeepers: updateObjectInArrayById(state.beekeepers, data),
                installers: updateObjectInArrayById(state.installers, data),
            };
        case FETCH_COMPANY_BY_NAME.success:
            return {
                ...state,
                existingCompanyOnCreation: data,
            };
        case TOGGLE_COUNTRIES:
            return {
                ...state,
                countriesToShow: state.countriesToShow.includes(country)
                    ? state.countriesToShow.filter(c => c !== country)
                    : [...state.countriesToShow, country],
            };
        case FETCH_COMPANY_USERS.success:
            return {
                ...state,
                companyUsers: action.data,
            };
        default:
            return state;
    }
};

export default adminDashboard;
