import { createStore, combineReducers, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import * as Sentry from '@sentry/react';
import { fork, all } from 'redux-saga/effects';

import { appSaga } from '@beewise/react-utils';
import { compose } from '@beewise/utils';
import sagas from 'sagas';
import * as reducers from 'reducers';
import { SIGN_OUT } from 'components/views/SignIn/actionTypes';
import { API_URL } from './config';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const isDevelopmentMode = process.env.NODE_ENV !== 'production';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Optionally pass options listed below
});

function combineSagas(sagas) {
    return function* combined() {
        yield all(sagas.map(fork));
    };
}

function configureStore() {
    const composeEnhancers = (isDevelopmentMode ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose) || compose;

    const store = createStore(
        combineReducers({ ...reducers }),
        composeEnhancers(applyMiddleware(...middlewares), sentryReduxEnhancer)
    );

    sagaMiddleware.run(
        combineSagas([() => appSaga('beewiseGrowerApp', API_URL, SIGN_OUT, isDevelopmentMode), ...sagas])
    );

    return store;
}

export default configureStore();
