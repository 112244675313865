import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Menu from '@beewise/menu';
import cn from 'classnames';
import Tooltip from '@beewise/tooltip';
import './MapMarkerWrapper.scss';

const MapMarkerWrapper = ({
    marker,
    onClick,
    selectedItem,
    isDisabled,
    type,
    cursorStyle,
    onMouseEnter,
    onMouseLeave,
    icon,
    halo,
    isRanchMarker,
    testId,
    onDragStart,
    isDragged,
    draggable,
    color,
    tooltipData,
    isBhomeMarker,
    menuItems = [],
}) => {
    const [active, setActive] = useState(false);

    const handleMarkerClick = useCallback(
        e => {
            e.stopPropagation();
            onClick({ ...marker, type }, e);
            setActive(true);
        },
        [onClick, marker, type]
    );

    const getBaseMarkerComponent = onClick => (
        <div style={{ opacity: isDragged ? 0 : 1 }}>
            <div
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                role="presentation"
                data-testid={testId}
                {...(onClick ? { onClick } : {})}
            >
                {(!isRanchMarker || isBhomeMarker) && (
                    <div
                        className={cn('marker-hives-amount', 'top', { 'is-bhome-marker': isBhomeMarker })}
                        style={cursorStyle}
                    >
                        {marker?.hivesAmount ?? 0}
                    </div>
                )}
                <div
                    style={cursorStyle}
                    className={cn('marker', type, marker?.type, {
                        'marker-selected': selectedItem?.id === marker?.id,
                        'marker-disabled': !!isDisabled,
                        'marker-active': selectedItem?.id === marker?.id && active,
                    })}
                    draggable={draggable}
                    role="presentation"
                    id={marker?.id}
                    onDragStart={onDragStart}
                >
                    <Tooltip
                        text={tooltipData}
                        disabled={isDisabled || !tooltipData}
                        inverted
                        position="top center"
                        offset={[0, 22, 0, 0]}
                    >
                        {icon}
                    </Tooltip>
                </div>
                {color && <div className="map-marker-color-line" style={{ backgroundColor: color }} />}
            </div>
            {halo}
        </div>
    );

    return menuItems?.length ? (
        <Menu options={menuItems} customComponent={getBaseMarkerComponent()} onClick={handleMarkerClick} />
    ) : (
        getBaseMarkerComponent(handleMarkerClick)
    );
};

MapMarkerWrapper.propTypes = {
    cursorStyle: PropTypes.shape(),
    halo: PropTypes.node,
    icon: PropTypes.node.isRequired,
    isDisabled: PropTypes.bool,
    isRanchMarker: PropTypes.bool,
    marker: PropTypes.shape().isRequired,
    onClick: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    selectedItem: PropTypes.shape(),
    type: PropTypes.string,
    testId: PropTypes.string,
    draggable: PropTypes.bool,
    onDragStart: PropTypes.func,
    isDragged: PropTypes.bool,
    color: PropTypes.string,
    menuItems: PropTypes.arrayOf(PropTypes.shape()),
    tooltipData: PropTypes.string,
    isBhomeMarker: PropTypes.bool,
};

export default MapMarkerWrapper;
