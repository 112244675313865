import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@beewise/button';
import { ROUTE_PATHS } from 'router/constants';
import './AddRanch.scss';

const AddRanch = ({ map }) => {
    const countriesToShow = useSelector(state => state.adminDashboard.countriesToShow);
    const navigate = useNavigate();
    const header = countriesToShow.length ? 'All Ranches' : 'All My Ranches';

    return (
        <div className="add-ranch-container">
            <h3 className="add-ranch-text">{header}</h3>
            <Button
                className="primary-blue"
                onClick={() =>
                    navigate(ROUTE_PATHS.RANCH, {
                        state: {
                            map: {
                                lat: map?.getCenter()?.lat(),
                                lng: map?.getCenter()?.lng(),
                                zoom: map?.getZoom(),
                            },
                        },
                    })
                }
                shadow
            >
                Add ranch
            </Button>
        </div>
    );
};

AddRanch.propTypes = {
    map: PropTypes.shape({ getZoom: PropTypes.func, getCenter: PropTypes.func, zoom: PropTypes.number }),
};

export default AddRanch;
