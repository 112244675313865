import React, { Suspense, useEffect, useState } from 'react';
import { modalController } from './modalController';

export const ModalManager = () => {
    const [modal, setModal] = useState(null);

    useEffect(() => {
        const unsubscribe = modalController.subscribe(setModal);
        return () => unsubscribe();
    }, []);

    if (!modal) {
        return null;
    }

    const onClose = () => modalController.set(null);

    const Modal = modalController.get(modal.name);

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Modal {...modal?.props} isOpen onClose={onClose} suppressCoverClose />
        </Suspense>
    );
};
