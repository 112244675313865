import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Button from '@beewise/button';
import { toJpeg } from 'html-to-image';
import constants from 'appConstants';
import './RanchApproval.scss';
import cn from 'classnames';

const RanchApproval = ({ selectedRanchApproval, className, setRanchApprovalHandler }) => {
    const dispatch = useDispatch();

    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleDisableSubmitting = useCallback(() => setIsSubmitting(false), []);

    const approveRequest = useCallback(async () => {
        setIsSubmitting(true);
        const rootNode = document.getElementById('app');
        const base64Data = await toJpeg(rootNode, { quality: 0.8 });
        const blob = new Blob([base64Data], { type: 'image/jpeg' });
        const formData = new FormData();
        formData.append('file', blob, `${selectedRanchApproval.id}.jpeg`);
        formData.append('status', constants.RANCH_APPROVAL_STATUSES.APPROVED);
        dispatch(
            setRanchApprovalHandler({
                ranchId: selectedRanchApproval.ranchId,
                approvalId: selectedRanchApproval.id,
                body: formData,
                resolver: handleDisableSubmitting,
                rejector: handleDisableSubmitting,
            })
        );
    }, [
        selectedRanchApproval.id,
        selectedRanchApproval.ranchId,
        dispatch,
        setRanchApprovalHandler,
        handleDisableSubmitting,
    ]);

    const handleContactClick = useCallback(() => {
        if (selectedRanchApproval.status === constants.RANCH_APPROVAL_STATUSES.REQUESTED) {
            dispatch(
                setRanchApprovalHandler({
                    ranchId: selectedRanchApproval.ranchId,
                    approvalId: selectedRanchApproval.id,
                    body: { status: constants.RANCH_APPROVAL_STATUSES.DECLINED },
                })
            );
        }
        const mailtoLink = `mailto:${selectedRanchApproval.requestedByEmail}`;
        window.location.href = mailtoLink;
    }, [
        dispatch,
        selectedRanchApproval.id,
        selectedRanchApproval.ranchId,
        selectedRanchApproval.requestedByEmail,
        selectedRanchApproval.status,
        setRanchApprovalHandler,
    ]);

    const renderContent = useCallback(() => {
        switch (selectedRanchApproval.status) {
            case constants.RANCH_APPROVAL_STATUSES.APPROVED:
                return (
                    <p className="ranch-approval-text">
                        Layout approved on: {new Date(selectedRanchApproval.approvedAt).toLocaleString()}
                    </p>
                );
            case constants.RANCH_APPROVAL_STATUSES.REQUESTED:
                return (
                    <>
                        <p className="ranch-approval-text">
                            Please review the drop layout and approve. Contact us if changes are required.
                        </p>
                        <div className="ranch-approval-footer">
                            <Button shadow onClick={handleContactClick} className="secondary-blue">
                                Contact Us
                            </Button>
                            <Button shadow onClick={approveRequest} className="primary-blue" disabled={isSubmitting}>
                                Approve
                            </Button>
                        </div>
                    </>
                );
            case constants.RANCH_APPROVAL_STATUSES.DECLINED:
                return (
                    <>
                        <p className="ranch-approval-text">You requested changes in the drop layout.</p>
                        <div className="ranch-approval-footer">
                            <Button shadow onClick={handleContactClick} className="secondary-blue">
                                Contact Us
                            </Button>
                        </div>
                    </>
                );
            default:
                return null;
        }
    }, [
        approveRequest,
        handleContactClick,
        selectedRanchApproval.approvedAt,
        selectedRanchApproval.status,
        isSubmitting,
    ]);

    return <div className={cn('ranch-approval-container', className)}>{renderContent()}</div>;
};

export default RanchApproval;

RanchApproval.propTypes = {
    selectedRanchApproval: PropTypes.shape({
        id: PropTypes.string.isRequired,
        ranchId: PropTypes.string.isRequired,
        status: PropTypes.oneOf(Object.values(constants.RANCH_APPROVAL_STATUSES)).isRequired,
        approvedAt: PropTypes.string,
        requestedByEmail: PropTypes.string.isRequired,
    }).isRequired,
    className: PropTypes.string,
    setRanchApprovalHandler: PropTypes.func.isRequired,
};
