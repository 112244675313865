import React from 'react';
import { capitalize } from 'lodash-es';
import constants from 'appConstants';
import ManageGridWrapper from 'components/reusables/ManageGridWrapper';
import PropTypes from 'prop-types';
import useGridColInit from './useGridColumnInit';

const AdminTable = ({ type, dataSelector, isLoadingSelector }) => {
    const { handleFetchCompany, columns, handleAdd } = useGridColInit({ type });
    return (
        <ManageGridWrapper
            title={`Manage ${capitalize(type)}s`}
            description={`View, add and manage all of your ${type}s`}
            columns={columns}
            dataSelector={dataSelector}
            fetchData={handleFetchCompany}
            handleAdd={handleAdd}
            addButtonText={`Add ${type}`}
            isLoadingSelector={isLoadingSelector}
        />
    );
};

AdminTable.propTypes = {
    type: PropTypes.oneOf([
        constants.COMPANY_TYPES.INSTALLER,
        constants.COMPANY_TYPES.GROWER,
        constants.COMPANY_TYPES.BEEKEEPER,
    ]),
    dataSelector: PropTypes.func,
    isLoadingSelector: PropTypes.func,
};

export default AdminTable;
