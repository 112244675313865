import dayjs from 'dayjs';
import appConstants from 'appConstants';

export default (selectedContentData, crop, appSettings) => {
    let potential = 0;
    let potentialTitle = 'Low';
    const graphData = appConstants.GRAPH_DATA[crop] || appConstants.GRAPH_DATA.almonds;

    if (selectedContentData && crop) {
        // before we was counting -22 days because of capped brood. How we do it now?
        const latestDate = dayjs.utc().subtract(1, 'day').startOf('day');
        const currentDateData = selectedContentData.data.find(subItem =>
            latestDate.isSame(dayjs.utc(subItem.date), 'day')
        );
        if (currentDateData) {
            potential = Number(currentDateData[graphData.KEY]);
        }
        if (potential >= appSettings.highPotentialLevel) {
            potentialTitle = 'High';
        } else if (potential >= appSettings.mediumPotentialLevel) {
            potentialTitle = 'Medium';
        }
    }

    return {
        potential,
        potentialTitle,
    };
};
