import { useCallback, useRef, useState } from 'react';

const useMap = ({ setMap }) => {
    const mapRef = useRef(null);
    const [, setMapOptions] = useState({});

    const handleMapChange = useCallback(({ center, zoom, bounds }) => setMapOptions({ center, zoom, bounds }), []);
    const onGoogleApiLoaded = useCallback(({ map }) => setMap(map), [setMap]);

    return { handleMapChange, onGoogleApiLoaded, mapRef };
};

export default useMap;
