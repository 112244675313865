import { lazy } from 'react';

export const MODALS = {
    CREATE_LOCATION: 'CREATE_LOCATION',
    REMOVE_CONFIRMATION: 'REMOVE_CONFIRMATION',
    ADD_USER: 'ADD_USER',
    ADD_COMPANY: 'ADD_COMPANY',
    AUTO_PLACE: 'AUTO_PLACE',
    UNIVERSAL_TEXT_MODAL: 'UNIVERSAL_TEXT_MODAL',
    SEND_RANCH_LOCATION: 'SEND_RANCH_LOCATION',
    MANAGE_INSTALLERS_AND_BEEKEEPERS: 'MANAGE_INSTALLERS_AND_BEEKEEPERS',
    ADMIN_DASHBOARD_USER_MODAL: 'ADMIN_DASHBOARD_USER_MODAL',
    PIN_MODAL: 'PIN_MODAL',
};

export const modalsConfig = {
    [MODALS.CREATE_LOCATION]: lazy(() => import('./CreateLocationModal')),
    [MODALS.REMOVE_CONFIRMATION]: lazy(() => import('./RemoveConfirmationModal')),
    [MODALS.ADD_USER]: lazy(() => import('./AddUserModal')),
    [MODALS.ADD_COMPANY]: lazy(() => import('./AddCompanyModal')),
    [MODALS.AUTO_PLACE]: lazy(() => import('./AutoPlaceModal')),
    [MODALS.UNIVERSAL_TEXT_MODAL]: lazy(() => import('./UniversalTextModal')),
    [MODALS.SEND_RANCH_LOCATION]: lazy(() => import('./SendRanchLocation')),
    [MODALS.MANAGE_INSTALLERS_AND_BEEKEEPERS]: lazy(() => import('./ManageInstallersAndBeekeepersModal')),
    [MODALS.ADMIN_DASHBOARD_USER_MODAL]: lazy(() => import('./AdminDashboardUserModal')),
    [MODALS.PIN_MODAL]: lazy(() => import('./PinModal')),
};
