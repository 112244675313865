import PropTypes from 'prop-types';
import cn from 'classnames';
import React, { useMemo } from 'react';
import Tooltip from '@beewise/tooltip';
import './TimelineGraph.scss';

const colors = {
    yellow: '#fdba12',
    gray: '#BDBDBD',
};

const getDotStyle = ({ index, substatus, large, i, tooltipText }) => {
    const style = {};
    if (i <= index) {
        style.backgroundColor = colors.yellow;
    }
    if (i === index && substatus) {
        style.backgroundColor = '#F6F6F5';
        style.border = `1px solid ${colors.yellow}`;
    } else if (i > index && large) {
        style.border = `1px solid ${colors.gray}`;
    } else if (i > index && !large) {
        style.backgroundColor = colors.gray;
    }
    if (tooltipText) {
        style.cursor = 'pointer';
    }
    return style;
};

const TimelineGraph = ({
    points = 4,
    activeIndex: { index, substatus },
    name,
    secondaryName,
    dashed,
    large,
    tooltipText,
}) => {
    const percentStep = 100 / (points - 1);
    const percents = index * percentStep;

    const timelineLabelStyle = useMemo(() => {
        const styles = {};

        if (index > 0 && index < points - 1) {
            styles.transform = 'translateX(-50%)';
            styles.left = `${percents}%`;
        }
        if (index === points - 1) {
            styles.right = 0;
        }
        return styles;
    }, [percents, index, points]);

    const timeLinePoints = useMemo(
        () =>
            new Array(points).fill().map((point, i) => {
                const dotStyle = getDotStyle({
                    index,
                    i,
                    substatus,
                    large,
                    tooltipText,
                });
                const dot = <span className={`point point-${large ? 'large' : 'small'}`} style={dotStyle} />;

                return (
                    <React.Fragment key={i}>
                        {tooltipText ? (
                            <Tooltip text={tooltipText[i]} className="timeline-tooltip">
                                {dot}
                            </Tooltip>
                        ) : (
                            dot
                        )}
                        {i !== points - 1 && (
                            <span
                                className={cn('line', dashed && 'dashed')}
                                style={{
                                    flex: 1,
                                    height: '1px',
                                    backgroundColor: i <= index ? colors.yellow : colors.gray,
                                }}
                            />
                        )}
                        {i === index && name && (
                            <div className="point-name" style={timelineLabelStyle}>
                                <div className="point-name-main">{name.replace('_', ' ')}</div>
                                <div className="point-name-secondary">{secondaryName}</div>
                            </div>
                        )}
                    </React.Fragment>
                );
            }),
        [points, index, name, secondaryName, timelineLabelStyle, dashed, large, substatus, tooltipText]
    );

    return <div className="timeline-graph">{timeLinePoints}</div>;
};

export default TimelineGraph;

TimelineGraph.propTypes = {
    large: PropTypes.bool,
    activeIndex: PropTypes.shape({
        substatus: PropTypes.bool,
        index: PropTypes.number,
    }),
    dashed: PropTypes.bool,
    name: PropTypes.string,
    points: PropTypes.number.isRequired,
    secondaryName: PropTypes.string,
    tooltipText: PropTypes.oneOfType([PropTypes.shape(), PropTypes.arrayOf(PropTypes.string)]),
};
