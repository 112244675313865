import { useMemo } from 'react';
import dayjs from 'dayjs';

const useDataForChart = dataModel =>
    useMemo(() => {
        if (!dataModel) {
            return;
        }

        return dataModel.map(entry => ({
            date: dayjs(entry.date).format('MM/DD'),
            beeFramesPerHive: entry.beeFramesPerHive,
            beeFrameStandard: entry.beeFrameStandard,
            broodFramesPerHive: entry.broodFramesPerHive,
            honeyFramesPerHive: entry.honeyFramesPerHive,
        }));
    }, [dataModel]);

export default useDataForChart;
