import PropTypes from 'prop-types';
import React from 'react';
import { isNil } from 'lodash-es';

const Row = ({ primaryText = '', secondaryText = '' }) => (
    <p className="ranches-info-regular wide">
        <span className="primary">{primaryText}</span>
        <span className="secondary">{isNil(secondaryText) ? '' : `: ${secondaryText}`}</span>
    </p>
);

Row.propTypes = {
    primaryText: PropTypes.string,
    secondaryText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Row;
