import React, { forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faForklift } from '@fortawesome/pro-regular-svg-icons';

const LoadingZoneIcon = forwardRef((props, ref) => (
    <span ref={ref} {...props} className="icon-loading-zone-container">
        <FontAwesomeIcon icon={faForklift} className="icon-loading-zone" />
    </span>
));

export default LoadingZoneIcon;
