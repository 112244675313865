import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '@beewise/button';
import SearchBox from '../SearchBox';
import SquareMap from './square-map.svg';
import SatelliteMap from './satellite-map.svg';

const ROADMAP = 'roadmap';
const HYBRID = 'hybrid';

const ZoomButton = ({ incrementBy, children, onZoom }) => (
    <Button onClick={() => onZoom(incrementBy)}>{children}</Button>
);

ZoomButton.propTypes = {
    incrementBy: PropTypes.number.isRequired,
    children: PropTypes.node.isRequired,
    onZoom: PropTypes.func.isRequired,
};

const MapTypePicker = ({ currentMapType, onToggle }) => (
    <img
        role="presentation"
        className="map-type-picker"
        src={currentMapType === HYBRID ? SquareMap : SatelliteMap}
        alt="type"
        onClick={onToggle}
    />
);

MapTypePicker.propTypes = {
    currentMapType: PropTypes.oneOf([ROADMAP, HYBRID]).isRequired,
    onToggle: PropTypes.func.isRequired,
};

const MapControls = ({ map, onMouseEnter, onMouseLeave, customIcon, withoutSearch }) => {
    const handleZoomChange = useCallback(
        amount => {
            map.setZoom(map.getZoom() + amount);
        },
        [map]
    );

    const handleMapTypeChange = useCallback(() => {
        const newType = map.mapTypeId === HYBRID ? ROADMAP : HYBRID;
        map.setMapTypeId(newType);
    }, [map]);

    return (
        <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            {!withoutSearch && (
                <div className="map-controls">
                    <SearchBox map={map} customIcon={customIcon} />
                </div>
            )}
            <MapTypePicker currentMapType={map.mapTypeId} onToggle={handleMapTypeChange} />
            <div className="map-zoom-controls">
                <ZoomButton incrementBy={1} onZoom={handleZoomChange}>
                    +
                </ZoomButton>
                <ZoomButton incrementBy={-1} onZoom={handleZoomChange}>
                    &#8211;
                </ZoomButton>
            </div>
        </div>
    );
};

MapControls.propTypes = {
    map: PropTypes.shape({
        getZoom: PropTypes.func.isRequired,
        setZoom: PropTypes.func.isRequired,
        setCenter: PropTypes.func.isRequired,
        mapTypeId: PropTypes.string.isRequired,
        setMapTypeId: PropTypes.func.isRequired,
    }).isRequired,
    onMouseLeave: PropTypes.func,
    onMouseEnter: PropTypes.func,
    customIcon: PropTypes.node,
    withoutSearch: PropTypes.bool,
};

export default MapControls;
