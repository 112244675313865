import { useEffect, useRef } from 'react';

export function useEventListener(eventName, handler, element = window) {
    const savedHandler = useRef(handler);

    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(() => {
        if (!element?.addEventListener) {
            return;
        }

        const eventListener = event => savedHandler.current(event);

        element.addEventListener(eventName, eventListener);

        return () => element.removeEventListener(eventName, eventListener);
    }, [eventName, element]); // Re-run if eventName or element changes
}
