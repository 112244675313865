import React from 'react';
import constants from 'appConstants';
import AdminTable from '../../components/AdminTable';
import { getInstallers } from '../../selectors';

const ManageInstallers = () => (
    <AdminTable
        type={constants.COMPANY_TYPES.INSTALLER}
        dataSelector={getInstallers}
        isLoadingSelector={state => state.ui.loading.FETCH_INSTALLERS_NEW_FLOW}
    />
);

export default ManageInstallers;
