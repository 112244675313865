import { useEffect, useMemo } from 'react';
import { pick } from 'lodash-es';
import { useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { convertArea, COUNTRY_US } from 'utils/isIsraeliRanch';
import { clearUndefined } from 'utils/clearUndefined';
import { useForm } from 'react-hook-form';
import { fetchRanch } from '../actions';
import { getSelectedItem } from '../../../selectors';

export const getPolygonCountry = polygon => {
    const [coords] = polygon.getPath().getArray();
    const lng = coords.lng();
    return lng > 0 ? 'il' : 'us';
};

const defaultValues = {
    name: '',
    companyId: undefined,
    contact: null,
    ranchManager: null,
    totalArea: '',
    requiredHivesAmount: '',
    quality: '',
    crop: undefined,
    locations: [],
    blocks: [],
    pins: [],
    country: COUNTRY_US,
    contactInvitation: undefined,
    ranchManagerInvitation: undefined,
    companyInvitation: undefined,
    loadingZoneCoordinates: undefined,
    accessRoadCoordinates: undefined,
};

const useInitFormData = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    const isEditMode = !!id;

    const selectedRanch = useSelector(getSelectedItem, shallowEqual);

    useEffect(() => {
        if (id && selectedRanch?.id !== id) {
            dispatch(fetchRanch(id));
        }
    }, [selectedRanch?.id, id, dispatch]);

    const initialFields = useMemo(() => {
        if (!(isEditMode && selectedRanch)) {
            return defaultValues;
        }

        const pickedValues = pick(selectedRanch, [
            'name',
            'crop',
            'totalArea',
            'requiredHivesAmount',
            'quality',
            'companyId',
            'blocks',
            'country',
            'locations',
            'pins',
            'loadingZoneCoordinates',
            'accessRoadCoordinates',
        ]);
        const clearedValues = clearUndefined(pickedValues);

        return isEditMode && selectedRanch
            ? {
                  ...defaultValues,
                  ...clearedValues,
                  blocks: clearedValues?.blocks?.map(({ totalArea, ...block }) => ({
                      ...block,
                      totalArea: Number(convertArea(totalArea, selectedRanch.country).toFixed(0)),
                  })),
                  totalArea: Number(convertArea(selectedRanch.totalArea, selectedRanch.country).toFixed()),
                  requiredHivesAmount: selectedRanch.requiredHivesAmount,
                  ranchManager: selectedRanch.ranchManager?.id,
              }
            : defaultValues;
    }, [isEditMode, selectedRanch]);

    const form = useForm({ defaultValues: initialFields });

    useEffect(() => {
        if (isEditMode) {
            form.reset(initialFields);
        }
    }, [initialFields, form, isEditMode]);

    return form;
};

export default useInitFormData;
