import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { shallowEqual } from '@beewise/react-utils';
import Button from '@beewise/button';
import PropTypes from 'prop-types';
import Tooltip from '@beewise/tooltip';
import cn from 'classnames';
import { getBeekeepersColorsById } from 'components/views/AdminView/selectors';

const RoundIcon = ({ color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="6" viewBox="0 0 21 6" fill="none">
        <path d="M3 3H18" stroke={color} strokeWidth="5" strokeLinecap="round" />
    </svg>
);

const hexColorPropType = (props, propName, componentName) => {
    const prop = props[propName];
    if (!prop) {
        return new Error(`${propName} is required in ${componentName}. Validation failed.`);
    }
    if (typeof prop !== 'string') {
        return new Error(
            `Invalid prop type: ${propName} is expected to be a string in ${componentName}. Validation failed.`
        );
    }
    if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(prop)) {
        return new Error(
            `Invalid prop value: ${propName} in ${componentName} should be a valid hex color. Validation failed.`
        );
    }
    return null;
};

hexColorPropType.isRequired = (props, propName, componentName) => {
    const prop = props[propName];
    if (prop == null) {
        return new Error(`${propName} is required in ${componentName}. Validation failed.`);
    }
    return hexColorPropType(props, propName, componentName);
};

RoundIcon.propTypes = {
    color: hexColorPropType.isRequired,
};

const Row = ({ primaryText, secondaryText, color }) => (
    <div className={cn('ranches-info-beekeeper-container', 'wide')}>
        <div className="ranches-info-beekeeper">
            <div className="ranches-info-beekeeper-primary">
                <RoundIcon color={color ?? '#808080'} />
                <p className="ranches-info-regular">
                    <span className="primary">{primaryText}</span>
                    {secondaryText && <span className="primary">: </span>}
                </p>
            </div>
        </div>
        {secondaryText && (
            <p className="ranches-info-regular">
                <span className="secondary">{secondaryText}</span>
            </p>
        )}
    </div>
);

Row.propTypes = {
    primaryText: PropTypes.string,
    secondaryText: PropTypes.string,
    color: PropTypes.string,
};

const BeekeeperColumn = ({ orders, handleOpenModal, isManageDisabled }) => {
    const beekeepersColorsById = useSelector(getBeekeepersColorsById, shallowEqual);

    const beekeepers = useMemo(
        () =>
            orders.reduce((acc, order) => {
                // TODO map over beekeepers after migration
                if (order.beekeeperId) {
                    acc.push(
                        <Row
                            primaryText={order.beekeeperName}
                            secondaryText={`${order.hivesAmount} hives`}
                            key={order.id}
                            color={beekeepersColorsById[order.beekeeperId]}
                        />
                    );
                }
                return acc;
            }, []),
        [beekeepersColorsById, orders]
    );

    return (
        <div className="ranches-info-column">
            <h3 className="ranches-info-header">Beekeepers</h3>
            {beekeepers}
            <Tooltip
                position="bottom"
                content="Can’t manage beekeepers since no drops were created for this ranch"
                key="manage-beekeepers-tooltip"
                disabled={!isManageDisabled}
            >
                <div>
                    <Button
                        key="manage-installers"
                        className="text-blue"
                        onClick={handleOpenModal}
                        disabled={isManageDisabled}
                    >
                        manage
                    </Button>
                </div>
            </Tooltip>
        </div>
    );
};

BeekeeperColumn.propTypes = {
    orders: PropTypes.arrayOf(PropTypes.shape({})),
    handleOpenModal: PropTypes.func,
    isManageDisabled: PropTypes.bool.isRequired,
};

export default BeekeeperColumn;
