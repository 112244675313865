import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@beewise/icon';

import './AuthFormContainer.scss';

const AuthFormContainer = ({ children, title }) => (
  <div className="auth-form">
    <div className="auth-form-container">
      <Icon type="beewise-logo" className="logo" />
      <h2 className="auth-form-header">{title}</h2>
      {children}
    </div>
  </div>
);

AuthFormContainer.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired
};

export default AuthFormContainer;
