import { useEffect } from 'react';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { captureConsoleIntegration, httpClientIntegration } from '@sentry/integrations';
import { UI_ENV } from './config';

const isLocalDevelopment = window.location.href.includes('localhost');
const sentryDns = 'https://26df0ec76b0a40ad9b209911c930810d@o4504734858936320.ingest.sentry.io/4504734906843136';
const matchUrl = /^https:\/\/(?:dev-|stage-)?api\.beewise\.ag\//;

const initSentry = () =>
    Sentry.init({
        environment: UI_ENV,
        dsn: sentryDns,
        integrations: [
            // Sentry.browserTracingIntegration({ enableInp: true }),
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            }),
            Sentry.browserProfilingIntegration(),
            Sentry.replayIntegration({
                blockAllMedia: false,
                maskAllText: false,
                networkCaptureBodies: true,
                networkDetailAllowUrls: [window.location.origin, matchUrl],
                networkDetailDenyUrls: [/sign-in/i],
                networkRequestHeaders: ['X-Custom-Header'],
                networkResponseHeaders: ['X-Custom-Header'],
            }),
            Sentry.replayCanvasIntegration(),
            captureConsoleIntegration({
                levels: ['error'],
            }),
            httpClientIntegration({ failedRequestStatusCodes: [400, 500] }),
        ],
        release: process.env.REACT_APP_SENTRY_RELEASE,
        logErrors: true,
        replaysSampleRate: 1,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        normalizeDepth: 10,
        tracesSampleRate: 1.0,
        profilesSampleRate: 1.0,
        enabled: !isLocalDevelopment && UI_ENV !== 'stage',
    });

export default initSentry;
