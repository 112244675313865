import React from 'react';
import PropTypes from 'prop-types';
import Button from '@beewise/button';
import Tooltip from '@beewise/tooltip';
import Row from './Row';

const InstallerColumn = ({ installers, handleOpenModal, isManageDisabled }) => (
    <div className="ranches-info-column">
        <h3 className="ranches-info-header">Installers</h3>
        {installers.map(installer => (
            <Row primaryText={installer.name} key={installer.id} />
        ))}
        <Tooltip
            content="Can’t manage installers since no drops were created for this ranch"
            position="bottom"
            key="manage-installers-tooltip"
            disabled={!isManageDisabled}
        >
            <div>
                <Button
                    key="manage-installers"
                    className="text-blue"
                    onClick={handleOpenModal}
                    disabled={isManageDisabled}
                >
                    manage
                </Button>
            </div>
        </Tooltip>
    </div>
);

InstallerColumn.propTypes = {
    installers: PropTypes.arrayOf(PropTypes.shape()),
    handleOpenModal: PropTypes.func,
    isManageDisabled: PropTypes.bool.isRequired,
};

export default InstallerColumn;
