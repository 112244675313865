export const ROUTE_PATHS = {
    HOME: '/',
    SIGNIN: '/signin',
    SETUP_PASSWORD: '/setup-password',
    RANCH: '/ranch',
    GROWERS: '/growers',
    BEEKEEPERS: '/beekeepers',
    INSTALLERS: '/installers',
    CONFERENCE: '/conf',
    REPORT: '/report',
};
