import constants from 'appConstants';
import { groupBy, omit } from 'lodash-es';
import { convertArea } from 'utils/isIsraeliRanch';

const getInvitation = (userId, invitationData) => {
    if (!invitationData) {
        return { userId, invitationData };
    }
    const { name, email, ...invitation } = invitationData;
    const user = userId !== constants.INVITED_ID ? userId : undefined;
    const formattedInvitation =
        userId !== constants.INVITED_ID
            ? undefined
            : {
                  ...invitation,
                  email: email.replaceAll(' ', '').toLowerCase(),
                  username: name,
              };

    return { userId: user, invitationData: formattedInvitation };
};

const getCompanyInvitation = (companyId, companyInvitation) => {
    if (companyInvitation) {
        return { companyId: undefined, companyInvitation };
    }
    return { companyId, companyInvitation };
};

export const mapLocations = locations =>
    locations.map(({ lat, lng, id, name, hivesAmount, blockId, note }) => ({
        lat,
        lng,
        id,
        name,
        hivesAmount,
        blockId,
        note,
    }));

export const getExtraFieldsForEditMode = (locations, state) => {
    const { oldBlocksLocations, newBlocksLocations } = locations.reduce(
        (acc, location) => {
            const isBlockNew = location.blockId.includes(constants.NEW_BLOCK_PREFIX);
            const isLocationNew = location.id.includes(constants.NEW_LOCATION_PREFIX);
            const key = isBlockNew && isLocationNew ? 'newBlocksLocations' : 'oldBlocksLocations';
            acc[key].push(isBlockNew && isLocationNew ? omit(location, 'id') : location);
            return acc;
        },
        { oldBlocksLocations: [], newBlocksLocations: [] }
    );

    const groupedLocations = groupBy(newBlocksLocations, 'blockId');
    const blocks = state.blocks.map(({ id, totalArea, ...block }) => ({
        ...block,
        id,
        totalArea: convertArea(totalArea, state.country, true),
        locations: groupedLocations[id],
    }));

    return { locations: oldBlocksLocations, blocks };
};

export const getExtraFieldsForCreateMode = (locations, state) => {
    const groupedLocations = groupBy(locations, 'blockId');
    const blocks = state.blocks.map(({ id, ...block }) => ({
        ...block,
        locations: groupedLocations[id]?.map(location => omit(location, 'id')),
    }));

    return { blocks, pins: state.pins.map(({ id, createdBy, ...pin }) => pin) };
};

export const getInvitationsAndCompanyData = state => {
    const ranchManagerData = getInvitation(state.ranchManager, state.ranchManagerInvitation);
    const contactInvitationData = getInvitation(state.contact, state.contactInvitation);
    const companyData = getCompanyInvitation(state.companyId, state.companyInvitation);

    return {
        ranchManager: ranchManagerData.userId,
        ranchManagerInvitation: ranchManagerData.invitationData,
        contactInvitation: contactInvitationData.invitationData,
        companyId: companyData.companyId,
        companyInvitation: companyData.companyInvitation,
    };
};
