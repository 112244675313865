import {
    FETCH_COMPANY_USERS,
    FETCH_USER_SETTINGS,
    GET_ALL_COMPANIES,
    UPDATE_COMPANY_LAST_VISITED,
    FETCH_ALL_COMPANY_TYPES,
} from '../actionTypes';

const header = (
    state = {
        companies: [],
        notificationSettings: {},
        users: [],
        companyTypes: [],
    },
    action
) => {
    switch (action.type) {
        case GET_ALL_COMPANIES.success:
            return {
                ...state,
                companies: action.data.companies,
            };
        case FETCH_ALL_COMPANY_TYPES.success:
            return {
                ...state,
                companyTypes: action.data.types,
            };
        case FETCH_USER_SETTINGS.success:
            return {
                ...state,
                notificationSettings: action.data.settings?.data?.growerNotifications,
            };
        case FETCH_COMPANY_USERS.success:
            return {
                ...state,
                users: action.data,
            };
        case UPDATE_COMPANY_LAST_VISITED.success:
            return {
                ...state,
                companies: state.companies.map(company => {
                    if (company.id === action.data.visit.company_id) {
                        return {
                            ...company,
                            last_visited: action.data.visit.last_visited,
                        };
                    }
                    return company;
                }),
            };
        default:
            return state;
    }
};

export default header;
