import { useState, useCallback, useRef, useEffect } from 'react';
import { updateMapBounds } from 'components/reusables/Map/components/Map/utils';
import { identifyRanchByItem } from 'utils/identifyRanchByItem';

const getShapeConfig = (ranch, shapeType) => {
    if (shapeType === window.google.maps.Polygon) {
        return {
            strokeColor: '#feba12',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: 'transparent',
            paths: ranch?.blocks?.map(block => block.polygon),
        };
    } else if (shapeType === window.google.maps.Polyline) {
        return {
            strokeColor: '#4392F1',
            strokeWeight: 4,
            editable: false,
            path: ranch?.accessRoadCoordinates,
        };
    }
};

const useMap = ({ handleSelectCurrentItem, selectedItem, ranches }) => {
    const mapRef = useRef(null);
    const customListenersRef = useRef([]);
    const [map, setMap] = useState(null);
    const [mapOptions, setMapOptions] = useState({});
    const [polyShapes, setPolyShapes] = useState([]);
    const [polyLines, setPolyLines] = useState([]);

    const isBhomeView = mapOptions?.zoom > 11;

    const handleMapChange = useCallback(({ center, zoom, bounds }) => setMapOptions({ center, zoom, bounds }), []);

    const adjustMapZoomAndCenter = useCallback(() => {
        if (!selectedItem || !map) {
            return;
        }

        const ranch = identifyRanchByItem({ item: selectedItem, ranches });

        if (!ranch) {
            return;
        }

        const points = ranch?.blocks.flatMap(block => block.polygon);
        updateMapBounds(map, points);
    }, [map, ranches, selectedItem]);

    const createAndAddShape = useCallback(
        (ranch, ShapeType) => {
            const shapeConfig = getShapeConfig(ranch, ShapeType);
            const shape = new ShapeType(shapeConfig);
            shape.setMap(map);
            const customListener = shape.addListener('click', () => handleSelectCurrentItem(ranch));
            customListenersRef.current.push(customListener);
            return shape;
        },
        [handleSelectCurrentItem, map]
    );

    const removeMapShape = useCallback(shape => {
        window.google?.maps?.event?.clearInstanceListeners(shape);
        shape.setMap(null);
    }, []);

    useEffect(() => {
        if (!map || !ranches.length) {
            return;
        }

        const polygons = [];
        const polylines = [];

        ranches.forEach(ranch => {
            ranch?.blocks?.length && polygons.push(createAndAddShape(ranch, window.google.maps.Polygon));
            ranch?.accessRoadCoordinates && polylines.push(createAndAddShape(ranch, window.google.maps.Polyline));
        });

        setPolyShapes(polygons);
        setPolyLines(polylines);

        return () => {
            polyShapes.forEach(removeMapShape);
            polyLines.forEach(removeMapShape);
            setPolyShapes([]);
            setPolyLines([]);
            customListenersRef.current = [];
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createAndAddShape, map, ranches, removeMapShape]);

    useEffect(
        () => () =>
            customListenersRef?.current?.forEach(item => window.google?.maps?.event?.clearInstanceListeners(item)),
        []
    );

    useEffect(() => {
        adjustMapZoomAndCenter();
    }, [adjustMapZoomAndCenter]);

    return {
        map,
        setMap,
        mapRef,
        isBhomeView,
        handleMapChange,
        polyShapes,
        polyLines,
    };
};

export default useMap;
