import React, { useEffect, useMemo, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { keyBy } from 'lodash-es';
import GoogleMaps from '@beewise/google-maps';
import { useWatch } from 'react-hook-form';
import './Map.scss';
import Cursor from 'components/reusables/Map/components/Cursor';
import MapControls from 'components/reusables/Map/components/Map/MapControls';
import constants from 'appConstants';
import { MapMarker } from 'components/reusables/Map';
import { getCursor } from 'utils';
import LoadingZoneIcon from 'components/reusables/Map/components/LoadingZoneIcon';
import { renderPins, renderBlockLabels } from 'components/reusables/Map/components/MapAdditionalMarkers';
import ToolboxOptions from './ToolboxOptions';
import useMarkerMode from '../../hooks/useMarkerMode';
import useCustomCursor from './useCustomCursor';
import useMap from './useMap';

const {
    TOOLBOX_MODE,
    MAP: { DEFAULT_CENTER, API_KEY },
} = constants;

const DEFAULT_ZOOM = 6;

const Map = ({
    blockEdited,
    polygons,
    removePolygonFromView,
    setMap,
    map,
    toolboxOption,
    setToolboxOption,
    drawingManager,
    form,
}) => {
    const { onGoogleApiLoaded, handleMapChange, mapRef } = useMap({ setMap });

    const isDraggable = [TOOLBOX_MODE.PLACE_LOCATION, TOOLBOX_MODE.MOVE].includes(toolboxOption);

    const { setValue, control } = form;
    const [blocks, locations, pins, loadingZoneCoordinates, accessRoadCoordinates, name] = useWatch({
        name: ['blocks', 'locations', 'pins', 'loadingZoneCoordinates', 'accessRoadCoordinates', 'name'],
        control,
    });

    const averageRatio = useMemo(() => {
        const sum = blocks.reduce((acc, block) => acc + block.ratio, 0);
        return sum / blocks.length;
    }, [blocks]);

    const blocksById = useMemo(() => keyBy(blocks, 'id'), [blocks]);

    const { customCursor, handleEnableCustomCursor, handleDisableCustomCursor, setCustomCursor } = useCustomCursor({
        toolboxOption,
    });

    const { markerClickListener, draggedMarkerId, handleDragStart, addLocationMarker, handleMarkerMouseEnter } =
        useMarkerMode({
            toolboxOption,
            blockEdited,
            polygons,
            map,
            setValue,
            locations,
            pins,
            blocks,
            setCustomCursor,
        });

    const showCustomCursor = customCursor && (toolboxOption === TOOLBOX_MODE.PLACE_LOCATION || draggedMarkerId);

    const handleSetToolboxOption = useCallback(
        option => () => !blockEdited.id && setToolboxOption(option),
        [blockEdited.id, setToolboxOption]
    );

    useEffect(() => {
        if (!blockEdited.id) {
            return;
        }
        setToolboxOption(null);
    }, [blockEdited.id, setToolboxOption]);

    return (
        <div
            className={cn('map-container', {
                // FIXME: should be moved to getCursor from the utils
                'mark-access-road-mode': toolboxOption === constants.TOOLBOX_MODE.MARK_ACCESS_ROAD,
                // FIXME: should be moved to getCursor from the utils
                'mark-pins-mode': [
                    constants.TOOLBOX_MODE.MARK_PINS,
                    constants.TOOLBOX_MODE.MARK_GATES,
                    constants.TOOLBOX_MODE.MARK_ROAD_BLOCKS,
                ].includes(toolboxOption),
                // FIXME: ugly hotfix, needs to be changed, depends on setting cursor in createPolygon in useCreateInitialFigures hook
                'mark-loading-zone': toolboxOption === constants.TOOLBOX_MODE.MARK_LOADING_ZONE,
            })}
        >
            <ToolboxOptions
                polygons={polygons}
                blockEdited={blockEdited}
                toolboxOption={toolboxOption}
                handleSetToolboxOption={handleSetToolboxOption}
                removePolygonFromView={removePolygonFromView}
                map={map}
                blocks={blocks}
                loadingZoneCoordinates={loadingZoneCoordinates}
                accessRoadCoordinates={accessRoadCoordinates}
                addLocationMarker={addLocationMarker}
                drawingManager={drawingManager}
                setValue={setValue}
            />
            <div className={cn('map-wrapper', map && `map-zoom-${map.zoom}`)}>
                {showCustomCursor && <Cursor map={mapRef.current} zoom={map.zoom} ratio={averageRatio} />}
                <GoogleMaps
                    ref={mapRef}
                    containerProps={{
                        id: 'map',
                        onMouseEnter: handleEnableCustomCursor,
                        onMouseLeave: handleDisableCustomCursor,
                    }}
                    apiKey={API_KEY}
                    defaultCenter={DEFAULT_CENTER}
                    defaultZoom={DEFAULT_ZOOM}
                    onGoogleApiLoaded={onGoogleApiLoaded}
                    options={{
                        draggableCursor: getCursor(toolboxOption),
                        mapTypeId: 'hybrid',
                        fullscreenControl: false,
                        zoomControl: false,
                        minZoom: 1,
                        scaleControl: true,
                    }}
                    onChange={handleMapChange}
                >
                    {locations.map((item, index) => (
                        <MapMarker
                            key={item.id}
                            lat={item.lat}
                            lng={item.lng}
                            note={item.note}
                            marker={item}
                            cursorStyle={{
                                cursor: getCursor(toolboxOption),
                            }}
                            ratio={blocksById[item.blockId]?.ratio}
                            onMouseEnter={handleMarkerMouseEnter}
                            onMouseLeave={handleEnableCustomCursor}
                            onClick={markerClickListener}
                            country="us"
                            onDragStart={handleDragStart(item, index)}
                            isDragged={item.id === draggedMarkerId}
                            draggable={isDraggable}
                        />
                    ))}
                    {map?.zoom > 14 && renderPins({ pins, onClick: markerClickListener, name })}
                    {map?.zoom > 14 && renderBlockLabels({ blocks })}
                    {loadingZoneCoordinates && (
                        <LoadingZoneIcon lat={loadingZoneCoordinates.lat} lng={loadingZoneCoordinates.lng} />
                    )}
                </GoogleMaps>
                {map && (
                    <MapControls
                        withoutSearch
                        map={map}
                        onMouseEnter={handleDisableCustomCursor}
                        onMouseLeave={handleEnableCustomCursor}
                    />
                )}
            </div>
        </div>
    );
};

Map.propTypes = {
    blockEdited: PropTypes.shape(),
    polygons: PropTypes.arrayOf(PropTypes.shape()),
    removePolygonFromView: PropTypes.func,
    setMap: PropTypes.func,
    map: PropTypes.shape(),
    toolboxOption: PropTypes.string,
    setToolboxOption: PropTypes.func,
    drawingManager: PropTypes.shape(),
    form: PropTypes.shape(),
};

export default memo(Map);
