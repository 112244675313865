import { useCallback, useEffect, useRef } from 'react';

const useVirtualizedList = ({ selectedItem, ranches }) => {
    const listRef = useRef(null);
    const sizeMap = useRef({});

    const setSize = useCallback((index, size) => {
        sizeMap.current = { ...sizeMap.current, [index]: size };
        listRef.current.resetAfterIndex(index);
    }, []);

    const getItemSize = index => sizeMap.current[index] || 50;

    const handleScrollToItem = useCallback(() => {
        if (selectedItem?.id && ranches?.length && listRef?.current) {
            listRef.current.scrollToItem(
                ranches.findIndex(item => item.id === selectedItem?.id),
                'center'
            );
        }
    }, [ranches, selectedItem?.id]);

    useEffect(() => {
        handleScrollToItem();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItem?.id, ranches]);

    return { setSize, getItemSize, listRef };
};

export default useVirtualizedList;
