import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { shallowEqual } from '@beewise/react-utils';
import HivesInfoBlock from '../HivesInfoBlock';
import { getHivesAmount } from '../../../../selectors';
import './HivesInfoContainer.scss';

const keyToDescriptionMap = {
    requiredHivesAmount: 'Required hives',
    availableHives: 'Available hives',
    assignedHives: 'Assigned hives',
    placedHivesAmount: 'Installed hives',
};

const HivesInfoContainer = () => {
    const hivesAmount = useSelector(getHivesAmount, shallowEqual);

    return (
        <div className="hives-info-container">
            {Object.keys(keyToDescriptionMap).map((key, index) => (
                <HivesInfoBlock
                    key={index}
                    title={hivesAmount[key] ? Number(hivesAmount[key])?.toLocaleString?.() : 0}
                    description={keyToDescriptionMap[key]}
                />
            ))}
        </div>
    );
};

HivesInfoContainer.propTypes = {
    hivesAmount: PropTypes.shape({
        requiredHivesAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        availableHives: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        assignedHives: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        placedHivesAmount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
};

export default memo(HivesInfoContainer);
