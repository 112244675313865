import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import constants from 'appConstants';
import { faNote, faDungeon, faRoadBarrier, faQuestion } from '@fortawesome/pro-light-svg-icons';
import PropTypes from 'prop-types';
import noop from 'lodash-es';
import MapMarkerWrapper from '../MapMarkerWrapper';
import './PinMarker.scss';

const getIcon = type => {
    switch (type) {
        case constants.PIN_TYPES.PIN:
            return faNote;
        case constants.PIN_TYPES.GATE:
            return faDungeon;
        case constants.PIN_TYPES.ROAD_BLOCK:
            return faRoadBarrier;
        default:
            return faQuestion;
    }
};

export const getPinEntity = type => {
    switch (type) {
        case constants.PIN_TYPES.PIN:
            return 'Note';
        case constants.PIN_TYPES.GATE:
            return 'Gate';
        case constants.PIN_TYPES.ROAD_BLOCK:
            return 'Road block';
        default:
            return faQuestion;
    }
};

const PinMarker = props => {
    const tooltipData = props?.note && (
        <div className="bhome-marker-tooltip">
            <p>{props?.note}</p>
        </div>
    );

    return (
        <MapMarkerWrapper
            onClick={noop}
            {...props}
            icon={
                <span className={`icon-note-container ${props.type}-type`}>
                    <FontAwesomeIcon icon={getIcon(props.type)} className="icon-note" />
                </span>
            }
            isRanchMarker
            draggable={false}
            tooltipData={tooltipData}
        />
    );
};

PinMarker.propTypes = {
    note: PropTypes.string,
    type: PropTypes.string,
};

export default PinMarker;
