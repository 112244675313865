const almondsOptions = [
    { value: 'nonparreil', text: 'Nonparreil' },
    { value: 'monterey', text: 'Monterey' },
    { value: 'butte/padre', text: 'Butte/Padre' },
    { value: 'independence', text: 'Independence' },
    { value: 'carmel', text: 'Carmel' },
    { value: 'fritz', text: 'Fritz' },
    { value: 'wood colony', text: 'Wood Colony' },
    { value: 'aldrich', text: 'Aldrich' },
    { value: 'butte mixed', text: 'Butte Mixed' },
    { value: 'padre', text: 'Padre' },
    { value: 'price', text: 'Price' },
    { value: 'winters', text: 'Winters' },
    { value: 'supareil', text: 'Supareil' },
    { value: 'peerless', text: 'Peerless' },
    { value: 'mission', text: 'Mission' },
    { value: 'california', text: 'California' },
    { value: 'shasta', text: 'Shasta' },
    { value: 'sonora', text: 'Sonora' },
    { value: 'ruby', text: 'Ruby' },
    { value: 'avalon', text: 'Avalon' },
    { value: 'monarch', text: 'Monarch' },
    { value: 'durango', text: 'Durango' },
];

const avocadoOptions = [
    { value: 'haas', text: 'Haas' },
    { value: 'etinger', text: 'Etinger' },
    { value: 'reed', text: 'Reed' },
    { value: 'galil', text: 'Galil' },
    { value: 'pinkerton', text: 'Pinkerton' },
    { value: 'arad', text: 'Arad' },
];

export default crop => {
    switch (crop) {
        case 'almonds':
            return almondsOptions;
        case 'avocado':
            return avocadoOptions;
        default:
            return [];
    }
};
