import { useCallback, useReducer, useEffect } from 'react';
import { getDirtyFormFields } from '@beewise/react-utils';
import { useDispatch } from 'react-redux';

const reducer = (state, action) => {
    switch (action.type) {
        case 'change':
            return {
                ...state,
                [action.key]: action.value,
            };
        case 'increase':
            return {
                ...state,
                [action.key]: state[action.key] + action.value,
            };
        case 'decrease':
            return {
                ...state,
                [action.key]: state[action.key] - action.value,
            };
        case 'add_to_array':
            return {
                ...state,
                [action.key]: [...state[action.key], action.value],
            };
        case 'remove_from_array':
            return {
                ...state,
                [action.key]: state[action.key].filter(item => (item?.id || item) !== action.value),
            };
        case 'add_or_remove_from_array':
            const exists = state[action.key].find(item => (item?.id || item) === (action.value?.id || action.value));
            return {
                ...state,
                [action.key]: exists
                    ? state[action.key].filter(item => (item.id || item) !== (action.value?.id || action.value))
                    : [...state[action.key], action.value],
            };
        case 'rerender_state':
            return {
                ...state,
                ...action.value,
            };

        case 'change_by_id': {
            return {
                ...state,
                [action.key]: state[action.key].map(item => (item.id === action.value.id ? action.value : item)),
            };
        }
        default:
            return state;
    }
};

const useForm = ({ initialFields, preSubmit, onSubmit, callback, isEditMode }) => {
    const dispatch = useDispatch();

    const [state, localDispatch] = useReducer(reducer, initialFields);

    const handleLocalReducerChange = useCallback(
        (key, value, type = 'change') => localDispatch({ type, key, value }),
        [localDispatch]
    );

    useEffect(() => {
        handleLocalReducerChange(null, initialFields, 'rerender_state');
    }, [initialFields, handleLocalReducerChange]);

    const handleSubmit = useCallback(() => {
        if (onSubmit) {
            let enhancedData = state;
            if (preSubmit) {
                enhancedData = preSubmit(state);
            }
            if (!isEditMode) {
                dispatch(onSubmit(enhancedData, callback));
            } else {
                const dirtyFields = getDirtyFormFields(initialFields, enhancedData);
                dispatch(onSubmit(dirtyFields, callback));
            }
        }
        // toggleModal();
    }, [state, dispatch, isEditMode, initialFields, onSubmit, callback, preSubmit]);

    return {
        state,
        handleLocalReducerChange,
        handleSubmit,
    };
};

export default useForm;
