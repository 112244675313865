import { useState } from 'react';
import constants from 'appConstants';

const { TOOLBOX_MODE } = constants;

const useCustomCursor = ({ toolboxOption }) => {
    const [customCursor, setCustomCursor] = useState(false);

    const handleEnableCustomCursor = () => {
        if (toolboxOption === TOOLBOX_MODE.PLACE_LOCATION) {
            setCustomCursor(true);
        }
    };

    const handleDisableCustomCursor = () => setCustomCursor(false);
    return {
        customCursor,
        handleEnableCustomCursor,
        handleDisableCustomCursor,
        setCustomCursor,
    };
};

export default useCustomCursor;
