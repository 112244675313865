import React from 'react';
import constants from 'appConstants';
import AdminTable from '../../components/AdminTable';

import { getBeekeepers } from '../../selectors';

const ManageBeekeepers = () => (
    <AdminTable
        type={constants.COMPANY_TYPES.BEEKEEPER}
        dataSelector={getBeekeepers}
        isLoadingSelector={state => state.ui.loading.FETCH_BEEKEEPERS_NEW_FLOW}
    />
);

export default ManageBeekeepers;
