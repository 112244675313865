import React from 'react';
import constants from 'appConstants';
import AdminTable from '../../components/AdminTable';
import { getGrowers } from '../../selectors';

const ManageGrowers = () => (
    <AdminTable
        type={constants.COMPANY_TYPES.GROWER}
        dataSelector={getGrowers}
        isLoadingSelector={state => state.ui.loading.FETCH_GROWERS_NEW_FLOW}
    />
);

export default ManageGrowers;
