import { modalsConfig } from './config';

const subs = new Map();
let modal;

export const modalController = {
    subscribe(handler) {
        subs.set(handler, handler);

        return () => {
            subs.delete(handler);
        };
    },

    set(newModal) {
        modal = newModal;
        subs.forEach(handler => handler(modal));
    },

    get(modalName) {
        return modalsConfig[modalName] ?? null;
    },
};
