import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHexagon } from '@fortawesome/pro-solid-svg-icons';
import cx from 'classnames';
import getPollinationPotential from 'utils/getPollinationPotential';
import useAppSettings from 'utils/useAppSettings';
import MapMarkerWrapper from '../MapMarkerWrapper';
import './RanchMarker.scss';

const RanchMarker = ({ marker, contentData, ...props }) => {
    const appSettings = useAppSettings();

    const { potentialTitle } = useMemo(
        () => getPollinationPotential(contentData, marker.crop, appSettings),
        [appSettings, contentData, marker.crop]
    );
    const name = `${marker.name} Ranch (${marker.locations?.length || 0} locations, ${
        marker.totalPlannedHives || 0
    } hives)`;
    const isSomeHivesDeployed = marker.locations?.some(
        ({ placedHivesAmount }) => placedHivesAmount && placedHivesAmount > 0
    );
    const icon = useMemo(
        () => (
            <div className="hex-container">
                <FontAwesomeIcon icon={faHexagon} className={cx('hex', { deployed: isSomeHivesDeployed })} />
                <FontAwesomeIcon icon={faHexagon} className="marker-status" />
                <span className="marker-bhomes-number">{marker && marker.locations.length}</span>
            </div>
        ),
        [isSomeHivesDeployed, marker]
    );
    const header = marker.showPotential ? <div>{potentialTitle} pollination potential</div> : '';

    const tooltipData = (name || header) && (
        <div className="bhome-marker-tooltip">
            <p>{name}</p>
            <p>{header}</p>
        </div>
    );

    return <MapMarkerWrapper marker={marker} isRanchMarker icon={icon} tooltipData={tooltipData} {...props} />;
};

RanchMarker.propTypes = {
    cursorStyle: PropTypes.shape(),
    isDisabled: PropTypes.bool,
    marker: PropTypes.shape().isRequired,
    onClick: PropTypes.func.isRequired,
    selectedItem: PropTypes.shape(),
    contentData: PropTypes.shape(),
};

export default RanchMarker;
