import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import GoogleMaps from '@beewise/google-maps';
import { showToast } from '@beewise/toast';
import PropTypes from 'prop-types';
import cn from 'classnames';
import MapMarker from 'components/reusables/Map/components/MapMarker';
import MapControls from 'components/reusables/Map/components/Map/MapControls';
import { SearchBox } from 'components/reusables/Map';
import { identifyRanchByItem } from 'utils/identifyRanchByItem';
import constants from 'appConstants';
import renderMapAdditionalMarkers from 'components/reusables/Map/components/MapAdditionalMarkers';
import { getRanches } from '../../../selectors';
import { TOAST_TYPE } from '../../../../../constants';
import './MapView.scss';

const DEFAULT_ZOOM = 6;
const DEFAULT_CENTER = { lat: 36.77, lng: -119.41 };

const MAP_OPTIONS = {
    mapTypeId: 'hybrid',
    fullscreenControl: false,
    zoomControl: false,
    minZoom: 1,
    scaleControl: true,
};

const extractLocationsFromRanches = ranches => ranches.reduce((acc, ranch) => [...acc, ...ranch.locations], []);

const getMenuItems = ({ lat, lng, entity }) => [
    {
        label: `Copy ${entity} location`,
        onClick: () => {
            const locationString = `${lat}, ${lng}`;
            navigator.clipboard.writeText(locationString);
            showToast(`Latitude and Longitude of the ${entity} were copied to clipboard`, {
                toastType: TOAST_TYPE.SUCCESS,
            });
        },
    },
    {
        label: 'Open location via google maps',
        onClick: () => {
            const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
            window.open(googleMapsUrl, '_blank');
        },
    },
];

const MapView = ({ handleSelectCurrentItem, map, isBhomeView, setMap, handleMapChange }) => {
    const ranches = useSelector(getRanches);
    const [bounds, setBounds] = useState(null);

    const locations = useMemo(() => extractLocationsFromRanches(ranches), [ranches]);
    const currentMarkers = isBhomeView ? locations : ranches;

    const visibleMarkers = useMemo(() => {
        if (!bounds || !currentMarkers.length) {
            return [];
        }

        return currentMarkers.filter(marker => {
            const { lat, lng } = marker;
            return bounds?.contains({ lat, lng });
        });
    }, [bounds, currentMarkers]);

    useEffect(() => {
        if (!map) {
            return;
        }

        const onBoundsChanged = () => setBounds(map.getBounds());

        window?.google?.maps?.event?.addListener(map, 'bounds_changed', onBoundsChanged);

        return () => window?.google?.maps?.event?.clearListeners(map, 'bounds_changed');
    }, [map]);

    return (
        <div className="admin-map-container">
            {map && <SearchBox map={map} placeholder="Search location" />}
            <div className={cn('map-wrapper', { [`map-zoom-${map?.zoom}`]: !!map })}>
                <GoogleMaps
                    containerProps={{ id: 'map' }}
                    apiKey={constants.MAP.API_KEY}
                    defaultCenter={DEFAULT_CENTER}
                    defaultZoom={DEFAULT_ZOOM}
                    onGoogleApiLoaded={({ map }) => setMap(map)}
                    onChange={handleMapChange}
                    options={MAP_OPTIONS}
                >
                    {visibleMarkers.map(marker => (
                        <MapMarker
                            key={marker.id}
                            marker={marker}
                            lat={marker.lat}
                            lng={marker.lng}
                            note={marker.note}
                            country="us"
                            onClick={() => handleSelectCurrentItem(identifyRanchByItem({ item: marker, ranches }))}
                            menuItems={getMenuItems({ lat: marker.lat, lng: marker.lng, entity: 'drop' })}
                        />
                    ))}
                    {map?.zoom > 14 && renderMapAdditionalMarkers({ ranches, getMenuItems })}
                </GoogleMaps>
                {map && <MapControls map={map} withoutSearch />}
            </div>
        </div>
    );
};

MapView.propTypes = {
    handleSelectCurrentItem: PropTypes.func.isRequired,
    map: PropTypes.shape({
        zoom: PropTypes.number,
        getZoom: PropTypes.func,
        getBounds: PropTypes.func,
        addListener: PropTypes.func,
    }),
    isBhomeView: PropTypes.bool,
    mapRef: PropTypes.shape({}),
    setMap: PropTypes.func,
    handleMapChange: PropTypes.func,
};

export default MapView;
