import React from 'react';
import PropTypes from 'prop-types';
import LocationMarker from '../LocationMarker';
import RanchMarker from '../RanchMarker';

import './MapMarker.scss';

const MapMarker = ({ menuItems, ...props }) =>
    !props.marker?.locations ? <LocationMarker menuItems={menuItems} {...props} /> : <RanchMarker {...props} />;

MapMarker.propTypes = {
    contentData: PropTypes.shape(),
    country: PropTypes.string,
    cursorStyle: PropTypes.shape(),
    isDisabled: PropTypes.bool,
    marker: PropTypes.shape(),
    onClick: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    ratio: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    selectedItem: PropTypes.shape(),
    menuItems: PropTypes.arrayOf(PropTypes.shape()),
    selectedRanchQuality: PropTypes.number,
    isGrower: PropTypes.bool,
};

export default MapMarker;
