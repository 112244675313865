import { groupBy } from 'lodash-es';
import constants from 'appConstants';

const { CROP } = constants;

export const mapRanchContactAndManagerOptions = users =>
    users.reduce(
        (acc, { username, id, email, generalPermissions }) => {
            const option = { label: username || email, value: id };
            const { isRanchManager, isFarmManager: isContact } = generalPermissions;

            if (isContact) {
                acc.farmManagerOptions.push(option);
            }

            if (isRanchManager) {
                acc.ranchManagerOptions.push(option);
            }

            return acc;
        },
        { farmManagerOptions: [], ranchManagerOptions: [] }
    );

export const generateUniqueIdForNewItem = (array, idPrefix) => {
    let newIndex = array.length + 1;
    let newId = `${idPrefix}${newIndex}`;
    const existingIds = new Set();
    const existingNames = new Set();

    array.forEach(({ id, name }) => {
        existingIds.add(id);
        existingNames.add(name);
    });

    // Find a unique index for the new item
    while (existingIds.has(newId) || existingNames.has(newId)) {
        newIndex += 1;
        newId = `${idPrefix}${newIndex}`;
    }

    return newId;
};

export const cropOptions = Object.entries(CROP).map(([key, value]) => ({
    label: value,
    value: key,
}));

// Calculate placed drops in total and per block arranged by block id
export const calculatePlacedHivesAmount = ({ locations, blocks }) => {
    const groupedLocations = groupBy(locations, 'blockId');

    const blockSums = {};
    let ranchTotal = 0;

    blocks?.forEach(block => {
        const blockId = block.id;
        const locationsInBlock = groupedLocations[blockId] || [];
        const blockTotal = locationsInBlock.reduce((sum, location) => {
            const placedHives = Number(location.hivesAmount);
            return !Number.isNaN(placedHives) ? sum + placedHives : sum;
        }, 0);
        blockSums[blockId] = blockTotal;
        ranchTotal += blockTotal;
    });

    return {
        blockSums,
        ranchTotal,
    };
};
