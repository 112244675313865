import { SAVE_CONF_DATA, SEND_CONF_DATA } from './actions';
import { CREATE_RANCH } from '../AdminView/views/BrokerRanch/actionTypes';

const conference = (
    state = {
        companyData: {},
        recordId: null,
    },
    action
) => {
    switch (action.type) {
        case SAVE_CONF_DATA:
            return {
                ...state,
                companyData: action.payload,
            };
        case CREATE_RANCH.success:
            return {
                ...state,
                companyData: {
                    ...state.companyData,
                    ranchId: action.data?.ranch?.id,
                },
            };
        case SEND_CONF_DATA.success:
            return {
                ...state,
                recordId: action.data.id,
            };
        default:
            return state;
    }
};

export default conference;
